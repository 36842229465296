import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import ModalPopup from "Components/ModalPopup"
import BeforeAndAfterPicture from "Components/BeforeAndAfterPicture"
import {
  CarouselProvider,
  Slider,
  Slide,
  DotGroup,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import Arrow from "Assets/Arrow.svg"
import Social from "Assets/Social.svg"

import ShareWrapper from "Components/ShareWrapper"

import "./GalleryFeaturesListing.style.scss"
import { isBrowser } from "Utils/Helpers"
import ProgressiveImage from "Components/ProgressiveImg"
import BlockTransition, {
  ElemsTransitionPropType,
  ElemsTransitionDefaultProps,
} from "Components/BlockTransition"
import ShareButton from "Components/ShareButton"
import { useSharedContext } from "Src/contexts/SharedContext"
import { useLocation } from "@reach/router"

const SLIDER_SHARE_ID = "#fg"
const SHARE_ID = "#g"

export const GalleryFeaturesListing = ({
  imagesData,
  featuredTagIds,
  tagsData,
  t,
  pageContext = {},
  elemsTransition = ElemsTransitionDefaultProps,
}) => {
  const { isMobile } = useSharedContext()
  const { pathname, origin } = useLocation()

  const [activeTagIndex, setActiveTagIndex] = useState(null)
  const [activeSlideIndex, setActiveSlideIndex] = useState(null)
  const [imagesDatas, setImagesData] = useState(imagesData)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [popupImageData, setPopupImageData] = useState({})
  const [copySuccess, setCopySuccess] = useState(false)

  const sharePopupRef = useRef()
  const inputRef = useRef(null)

  useEffect(() => {
    const handleSharePopupClose = e => {
      if (activeSlideIndex === null) return
      if (sharePopupRef.current.contains(e.target)) return

      setActiveSlideIndex(null)
    }

    document.addEventListener("mousedown", handleSharePopupClose)

    return () =>
      document.removeEventListener("mousedown", handleSharePopupClose)
  }, [activeSlideIndex])

  const getRowCount = () => (isMobile ? 2 : 4)

  const getFeaturedImages = () => {
    if (!featuredTagIds.length) return null

    return imagesData.filter(
      ({ tagIds }) =>
        tagIds.filter(tagId => featuredTagIds.includes(tagId)).length,
    )
  }

  const copyToClipboard = () => {
    const el = inputRef.current
    el.select()
    document.execCommand("copy")
    setCopySuccess(true)
  }

  const handleShareToNotActiveChange = () => setIsPopupOpen(false)

  const handlePopupClose = () => setIsPopupOpen(false)

  const handleSharePopupOpen = index => setActiveSlideIndex(index)

  const handlePopupOpen = (
    src,
    largeSrc,
    caption,
    afterSrc,
    afterLargeSrc,
    id,
    description,
  ) => {
    setIsPopupOpen(true)

    setPopupImageData({
      src,
      largeSrc,
      caption,
      afterSrc,
      afterLargeSrc,
      id,
      description,
    })
  }

  const handleShareToActiveChange = (
    src,
    largeSrc,
    caption,
    afterSrc,
    afterLargeSrc,
    id,
    description,
  ) => {
    setIsPopupOpen(true)

    setPopupImageData({
      src,
      largeSrc,
      caption,
      afterSrc,
      afterLargeSrc,
      id,
      description,
    })
  }

  const renderTagsOnSlide = tagIds => {
    if (!tagIds.length) return null

    return (
      <div className="TagOnSlideWrapper">
        {tagIds.map(tag => (
          <span key={tag} className="TagOnSlide">
            {tagsData[tag].value}
          </span>
        ))}
      </div>
    )
  }

  const renderTagsOnImage = tagIds => {
    return (
      <div className="TagOnImageWrapper">
        {tagIds.map(tag => (
          <span key={tag} className="TagOnImage">
            {tagsData[tag].value}
          </span>
        ))}
      </div>
    )
  }

  const renderSharePopup = (imageSrc, index, title, description, hash) => {
    if (!isBrowser) return null

    const { meta } = pageContext ?? {}
    const { hash: pageHash = "" } = meta || {}

    // eslint-disable-next-line max-len
    const shareUrl = `${origin}${
      pageHash ? pathname.replace(`${pageHash}/`, "") : pathname
    }${hash}/${SLIDER_SHARE_ID}${index}`

    if (activeSlideIndex === index) return null

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div className="ModalPopup" ref={sharePopupRef}>
        <div className="ModalWrapper">
          {renderShareButtons(
            imageSrc,
            index,
            title,
            description,
            hash,
            SLIDER_SHARE_ID,
          )}
          <p className="Subtitle">
            {t("clientStories.shareLink")}
            {copySuccess ? (
              <span className="SuccessMessage">
                {t("clientStories.success")}
              </span>
            ) : null}
          </p>
          <div className="ModalLinkRow">
            <input
              ref={inputRef}
              className="UrlLink"
              type="text"
              value={shareUrl}
              readOnly
            />
            <button onClick={copyToClipboard} className="CopyLinkButton">
              {t("clientStories.copyUrl")}
            </button>
          </div>
        </div>
      </div>
    )
  }

  const renderSlideBlock = (imageData, index) => {
    const {
      hash,
      afterSrc,
      afterLargeSrc,
      largeSrc,
      src,
      highlightTitle,
      caption,
      tagIds,
      description,
    } = imageData
    const key = `${src}-${index}`

    return (
      <Slide key={key} index={key}>
        <div
          className="SlideBlock"
          id={`${SLIDER_SHARE_ID.replace("#", "")}${index}`}
        >
          <div className="MediaWrapper">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/no-noninteractive-element-interactions */}
            <ProgressiveImage
              src={src}
              largeSrc={largeSrc}
              alt="SlideImage"
              className="Image"
              onClick={() =>
                handlePopupOpen(
                  src,
                  largeSrc,
                  caption,
                  afterSrc,
                  afterLargeSrc,
                  index,
                  description,
                )
              }
            />
          </div>
          <div className="TextWrapper">
            <p className="MainTitle">{highlightTitle}</p>
            {renderTagsOnSlide(tagIds)}
            <p className="Title">{caption}</p>
            <p className="Content">{description}</p>
            <Social
              className="SocialIcon"
              onClick={() => handleSharePopupOpen(index)}
            />
            <div className="SharePopup">
              {renderSharePopup(largeSrc, index, caption, description, hash)}
            </div>
          </div>
        </div>
      </Slide>
    )
  }

  const renderSlider = images => {
    return (
      <Slider>
        {images.map((imageData, index) => renderSlideBlock(imageData, index))}
      </Slider>
    )
  }

  const renderImageBlock = (rowIndex, columnIndex) => {
    const id = popupImageData?.id
    const childIndex = rowIndex + getRowCount() * columnIndex

    if (imagesDatas.length < childIndex + 1) return null

    const {
      [childIndex]: {
        hash,
        afterSrc,
        afterLargeSrc,
        largeSrc,
        src,
        caption,
        tagIds,
        description,
      },
    } = imagesDatas

    const documentTitle = isBrowser ? document.title : ""
    const shareCaption = caption && caption.trim() ? caption : documentTitle

    if (activeTagIndex && !tagIds.find(tagId => tagId === activeTagIndex))
      return null

    return (
      <div
        className="ImageBlockWrapper"
        key={childIndex}
        id={`${SHARE_ID.replace("#", "")}${childIndex}`}
      >
        <ShareWrapper
          isActive={isPopupOpen && childIndex === id}
          itemId={childIndex}
          shareId={SHARE_ID}
          onIsActive={() =>
            handleShareToActiveChange(
              src,
              largeSrc,
              caption,
              afterSrc,
              afterLargeSrc,
              childIndex,
              description,
            )
          }
          onIsNotActive={handleShareToNotActiveChange}
          metaTitle={shareCaption}
          metaImageSrc={afterLargeSrc || largeSrc}
          metaDescription={description}
        >
          <div className="ImageBlockButtonWrapper">
            <button
              className="ImageBlockButton"
              onClick={() =>
                handlePopupOpen(
                  src,
                  largeSrc,
                  caption,
                  afterSrc,
                  afterLargeSrc,
                  childIndex,
                  description,
                )
              }
            >
              <div className="ImageWrapper">
                <ProgressiveImage
                  src={src}
                  largeSrc={largeSrc}
                  alt="Board"
                  className="Image"
                />
                <div className="ImageLayer" />
                {renderTagsOnImage(tagIds)}
              </div>
            </button>
            {renderShareButtons(
              largeSrc,
              childIndex,
              caption,
              description,
              hash,
            )}
          </div>
        </ShareWrapper>
        {caption && <p className="ImageBlockCaption">{caption}</p>}
      </div>
    )
  }

  const renderShareButtons = (
    src,
    id,
    caption,
    description,
    hash,
    shareId = SHARE_ID,
  ) => {
    if (!isBrowser) return null

    const { meta } = pageContext || {}
    const { hash: pageHash = "" } = meta

    // eslint-disable-next-line max-len
    const url = `${origin}${
      pageHash ? pathname.replace(`${pageHash}/`, "") : pathname
    }${hash}/${shareId}${id}`
    const documentTitle = isBrowser ? document.title : ""
    const shareCaption = caption && caption.trim() ? caption : documentTitle

    return (
      <div className="ShareButtonsWrapper">
        <p className="ShareTitle">{t("likePinterestBlock.share")}</p>
        <div className="ShareButtons">
          <ShareButton type="facebook" url={url} />
          <ShareButton type="twitter" url={url} twitterTitle={shareCaption} />
          <ShareButton
            type="pinterest"
            url={url}
            media={src}
            description={description}
          />
          <ShareButton
            type="email"
            url={url}
            media={src}
            subject={shareCaption}
            body={description}
          />
        </div>
      </div>
    )
  }

  const renderRow = rowIndex => {
    const imageCount = Math.ceil(imagesData.length / getRowCount())
    const imageMap = [...new Array(imageCount)]

    return (
      <div key={rowIndex} className="RowWrapper">
        {imageMap.map((_, columnIndex) =>
          renderImageBlock(rowIndex, columnIndex),
        )}
      </div>
    )
  }

  const renderTagButton = ({ value, id }) => {
    return (
      <button
        key={id}
        className={`TagButton ${activeTagIndex === id ? "isActive" : ""}`}
        data-label={value}
        onClick={() => {
          const imageData =
            id === null
              ? imagesData
              : imagesData.filter(({ tagIds }) =>
                  tagIds.find(tagId => tagId === id),
                )

          setActiveTagIndex(id)
          setImagesData(imageData)
        }}
      >
        <span className="TagButtonLabel">{value}</span>
      </button>
    )
  }

  const renderTagsSelector = () => {
    return (
      <div className="TagSelectorWrapper">
        {renderTagButton({ value: t("likePinterestBlock.all"), id: null })}
        {Object.values(tagsData).map(tag => renderTagButton(tag))}
      </div>
    )
  }

  const renderPopupBeforeAfter = () => {
    const { src, largeSrc, afterSrc, afterLargeSrc } = popupImageData

    return (
      <BeforeAndAfterPicture
        beforeImageSrc={src}
        beforeImageLargeSrc={largeSrc}
        afterImageSrc={afterSrc}
        afterImageLargeSrc={afterLargeSrc}
        beforeLabelValue={t("likePinterestBlock.before")}
        afterLabelValue={t("likePinterestBlock.after")}
        sliderWrapperPadding={50}
      />
    )
  }

  const renderPopup = () => {
    const { src, largeSrc, caption, afterSrc } = popupImageData ?? {}

    return (
      <ModalPopup
        isVisible={isPopupOpen}
        handleClose={handlePopupClose}
        className="GalleryFeaturesListingBlock TagsModalPopup"
      >
        <div className={`ImageWrapper ${afterSrc ? "isBeforeAfter" : ""}`}>
          {afterSrc ? (
            renderPopupBeforeAfter()
          ) : (
            <ProgressiveImage
              src={src}
              largeSrc={largeSrc}
              alt="Board"
              className="Image"
            />
          )}
        </div>
        {caption && <p className="Caption">{caption}</p>}
      </ModalPopup>
    )
  }

  const images = getFeaturedImages()
  const rowMap = Array.from({ length: getRowCount() })

  return (
    <BlockTransition elemsTransition={elemsTransition}>
      <div className="GalleryFeaturesListingBlock">
        {renderTagsSelector()}
        {!!images.length && (
          <div className="FeaturesListingSliderWrapper">
            <CarouselProvider
              totalSlides={images.length}
              infinite
              className="SlideWrapper"
            >
              <div className="CarouselRow">
                <ButtonBack>
                  <Arrow className="CarouselArrow" />
                </ButtonBack>
                {renderSlider(images)}
                <ButtonNext>
                  <Arrow className="CarouselArrow" />
                </ButtonNext>
              </div>
              <DotGroup />
            </CarouselProvider>
          </div>
        )}
        <div className="ContentWrapper">
          {rowMap.map((_, index) => renderRow(index))}
        </div>
      </div>
      {renderPopup()}
    </BlockTransition>
  )
}

GalleryFeaturesListing.propTypes = {
  imagesData: PropTypes.arrayOf(PropTypes.object).isRequired,
  featuredTagIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  tagsData: PropTypes.objectOf(PropTypes.object).isRequired,
  t: PropTypes.func.isRequired,
  pageContext: PropTypes.objectOf(PropTypes.object),
  elemsTransition: ElemsTransitionPropType,
}

export default withTranslation()(GalleryFeaturesListing)

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Animate as AnimateOverlay, useAnimate } from "react-simple-animate"

function AnimateChild({ play, start, end, duration, delay, easing, children }) {
  const { style, play: playFunc } = useAnimate({
    play,
    start,
    end,
    duration,
    delay,
    easing,
  })

  useEffect(() => {
    playFunc(play)
  }, [play, playFunc])

  if (
    typeof children === "string" ||
    typeof children === "number" ||
    children === null
  ) {
    return children
  }

  const {
    type,
    props,
    props: { style: childStyle, children: child },
  } = children

  // If child is DOM element
  if (typeof type === "string") {
    const Tag = type

    if (!child || !child.length) {
      return <Tag {...props} style={{ ...childStyle, ...style }} />
    }

    return (
      <Tag {...props} style={{ ...childStyle, ...style }}>
        {child}
      </Tag>
    )
  }

  return (
    <div>
      {React.cloneElement(children, { style: { ...childStyle, ...style } })}
    </div>
  )
}

function Animate({
  refProp,
  play,
  start,
  end,
  duration,
  delay,
  easing,
  children: child,
}) {
  return React.Children.map(child, children => (
    <AnimateChild
      refProp={refProp}
      play={play}
      start={start}
      end={end}
      duration={duration}
      delay={delay}
      easing={easing}
      children={children}
    />
  ))
}

const Transition = props => {
  const {
    isAnimationEnabled,
    delay,
    duration,
    fadeInIsEnabled,
    movementIsEnabled,
    movementSide,
    movementStartPosition,
    easing,
    children,
    play,
    refProp,
    overlayColor,
    overlaySide,
    overlayIsEnabled,
  } = props

  const renderTransition = () => {
    if (!isAnimationEnabled) {
      return children
    }

    const start = {}
    const end = {}

    if (fadeInIsEnabled) {
      //Was put to one so the animations doesnt start (because for now the animation does not work correctly)
      start.opacity = 1
      end.opacity = 1
    }

    if (movementIsEnabled) {
      start.transform =
        movementSide === "left" || movementSide === "right"
          ? `translateX(${
              movementSide === "right"
                ? movementStartPosition
                : `-${movementStartPosition}`
            })`
          : `translateY(${
              movementSide === "bottom"
                ? movementStartPosition
                : `-${movementStartPosition}`
            })`
      end.transform = "translate(0)"
    }

    return (
      <Animate
        refProp={refProp}
        play={play}
        start={start}
        end={end}
        duration={duration}
        delay={delay}
        easeType={easing}
      >
        {children}
      </Animate>
    )
  }

  const renderOverlay = () => {
    const start = { backgroundColor: overlayColor }
    const end = { backgroundColor: overlayColor }

    if (overlaySide === "top" || overlaySide === "bottom") end.height = 0
    if (overlaySide === "left" || overlaySide === "right") end.width = 0

    return (
      <div className={`OverlayTransition ${overlaySide}`}>
        <AnimateOverlay
          play={play}
          start={start}
          end={end}
          duration={duration}
          delay={delay}
          easeType={easing}
        />
      </div>
    )
  }

  const renderWithOverlay = () => {
    return (
      <div className="OverlayTransitionBlock" ref={refProp}>
        {renderOverlay()}
        {renderTransition()}
      </div>
    )
  }

  if (overlayIsEnabled) return renderWithOverlay()

  return renderTransition()
}

Transition.propTypes = {
  isAnimationEnabled: PropTypes.bool,
  delay: PropTypes.string,
  duration: PropTypes.string,
  fadeInIsEnabled: PropTypes.bool,
  movementIsEnabled: PropTypes.bool,
  movementSide: PropTypes.string,
  movementStartPosition: PropTypes.string,
  easing: PropTypes.string,
  children: PropTypes.node.isRequired,
  play: PropTypes.bool,
  overlayIsEnabled: PropTypes.bool,
  overlaySide: PropTypes.string,
  overlayColor: PropTypes.string,
  refProp: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
}

Transition.defaultProps = {
  isAnimationEnabled: false,
  delay: "",
  duration: "",
  fadeInIsEnabled: false,
  movementIsEnabled: false,
  movementSide: "",
  movementStartPosition: "",
  easing: "",
  play: false,
  overlayIsEnabled: false,
  overlaySide: "",
  overlayColor: "",
}

export default Transition

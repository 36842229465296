import React from "react"
import PropTypes from "prop-types"
import "./BlogPostAuthor.style.scss"
import ProgressiveImage from "Components/ProgressiveImg"
import { getPreviewImageSrc } from "Utils/Helpers"

const BlogPostAuthor = ({ imageSrc, name, description, backgroundColor }) => {
  const renderImage = () => {
    if (!imageSrc) return null

    return (
      <ProgressiveImage
        src={getPreviewImageSrc(imageSrc)}
        largeSrc={imageSrc}
        className="Image"
        alt="Author"
      />
    )
  }

  const renderName = () => {
    if (!name) return null

    return <p className="Name">{name}</p>
  }

  const renderDescription = () => {
    if (!description) return null

    return <p className="Description">{description}</p>
  }

  return (
    <div className="BlogPostAuthor" style={{ backgroundColor }}>
      <div className="ImageWrapper">{renderImage()}</div>
      <div className="ContentWrapper">
        {renderName()}
        {renderDescription()}
      </div>
    </div>
  )
}

BlogPostAuthor.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
}

export default BlogPostAuthor

import axios from "axios"
import moment from "moment"
const swApiPath = `${process.env.GATSBY_WORDPRESS_BASE_URL}/wp-json/sw/v1/bathfitter`
const wpApiPath = `${process.env.GATSBY_WORDPRESS_BASE_URL}/wp-json/wp/v2`

const config = {
  headers: { "Content-Type": "application/json" },
}

// Reusable get method
const get = (endpoint, data, apiPath, callback) => {
  const params = new URLSearchParams(data)

  return axios
    .get(`${apiPath}${endpoint}?${params.toString()}`, config)
    .then(response => {
      const { data, headers } = response
      callback(data, null, headers)
      return response?.data
    })
    .catch(error => {
      const { message: timeoutMessage } = error

      // Handle timeout error
      if (!timeoutMessage) {
        const {
          error: {
            response: {
              data: { message },
              headers,
            },
          },
        } = error

        callback(null, message, headers)
        return error?.response?.data
      } else {
        callback(null, timeoutMessage, {})
        return error?.response?.data
      }
    })
}

const post = (endpoint, data, apiPath, callback) =>
  axios
    .post(`${apiPath}${endpoint}`, data, config)
    .then(response => {
      const { data } = response
      callback(data, null)

      return data
    })
    .catch(error => {
      const {
        error: {
          response: {
            data: { message },
          },
        },
      } = error
      callback(null, message)
    })

// Find calendar request
const getCalendar = async (zipCode, callback) => {
  const request = get(
    "/find-calendar",
    { zip_code: zipCode },
    swApiPath,
    callback,
  )

  return request
}

// Available time slots request
const checkTimeSlotsAvailableForServiceType = async (
  zipCode,
  service,
  startDate,
  version,
  callback = () => {},
) => {
  const endOfNextMonth = moment(startDate)
    .add(1, "M")
    .endOf("month")
    .format("YYYY-MM-DD")

  const request = await post(
    "/ebooking-time-slot",
    {
      zip_code: zipCode,
      version,
      service,
      from: moment(startDate).format("YYYY-MM-DD"),
      to: endOfNextMonth,
    },
    swApiPath,
    callback,
  )

  return request
}

const fetchTimeSlotVerification = async ({
  TimeslotID,
  Address,
  City,
  StateProvince,
  ZipCode,
  FromDate,
  Service,
}) => {
  const url = `${swApiPath}/ebooking-time-slot-distance`
  const requestBody = {
    zip_code: ZipCode,
    service: Service,
    from: FromDate,
    address: Address,
    city: City,
    state: StateProvince,
    timeslot: TimeslotID,
  }

  try {
    const response = await axios.post(url, requestBody)
    return response
  } catch (error) {
    console.error("Error fetching data:", error)
  }
}

// Geolocation request
const getGeolocation = async (ipAddress, callback) => {
  const request = await get(
    "/geolocation",
    {
      clientIP: ipAddress,
    },
    wpApiPath,
    callback,
  )

  return request
}

// Store Location request
const getStore = async (data, callback) => {
  try {
    const request = await get("/location-details", data, wpApiPath, callback)

    return request
  } catch (error) {
    console.error(error)
  }
}

// Search pages request
const searchPages = ({ search, language, page, per_page, callback }) =>
  get(
    "/search",
    {
      search,
      language,
      page,
      per_page,
      subtype: "page",
      _embed: true,
    },
    wpApiPath,
    callback,
  )

// Get page data by id from rest api
const getPageById = (id, callback) => {
  if (!id) return {}

  return get("/preview", { preview_id: id }, wpApiPath, callback)
}

// Get most viewed post ids
const getMostViewedPostIds = async (data, callback) => {
  const request = get("/posts/views", data, wpApiPath, callback)

  return request
}

// Get user data
const getUserById = async (userId, callback) => {
  const request = get(`/users/${userId}`, {}, wpApiPath, callback)

  return request
}

// Add view to post
const addViewToPost = async postId => {
  const request = get("/posts/views/", { id: postId }, wpApiPath, () => {})

  return request
}

// Submit ninja form
const submitNinjaForm = async (
  formID,
  fieldState,
  settings,
  extra,
  LeadPOST,
  callback,
) => {
  const request = await post(
    "/submit-ninja-form",
    {
      id: formID,
      fieldState,
      settings,
      extra,
      LeadPOST: LeadPOST,
    },
    wpApiPath,
    callback,
  )

  return request
}

export {
  getCalendar,
  checkTimeSlotsAvailableForServiceType,
  getGeolocation,
  searchPages,
  getPageById,
  getStore,
  getMostViewedPostIds,
  getUserById,
  addViewToPost,
  fetchTimeSlotVerification,
  submitNinjaForm,
}

import React, { useState, useEffect, memo } from "react"
import PropTypes from "prop-types"

const InputField = ({ placeholder, onSubmit, value: propValue }) => {
  const [value, setValue] = useState("")

  useEffect(() => {
    setValue(propValue)
  }, [propValue])

  const handleChange = e => setValue(e.target.value)

  const handleKeyDown = e => {
    if (e.keyCode === 13) onSubmit(value)
  }

  return (
    <input
      className="InputField"
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
    />
  )
}

InputField.propTypes = {
  placeholder: PropTypes.string,
  onSubmit: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

InputField.defaultProps = {
  placeholder: "",
  onSubmit: () => {},
  value: "",
}

export default memo(InputField)

import React, { useState, useEffect } from "react"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import { navigate } from "gatsby"

import ModalPopup from "Components/ModalPopup"
import { isBrowser } from "Utils/Helpers"
import CookieService from "Utils/CookieService"
import NinjaForm from "Components/NinjaForm"
import {
  defaultLanguageCode,
  getLanguage,
  formatToWpLanguage,
} from "Utils/Language"
import { useSharedContext } from "Src/contexts/SharedContext"
import { useLocation } from "@reach/router"

import "./BookConsultation.style.scss"
import Cancel from "Assets/Cancel.svg"

const BookConsultation = ({ widgets, languageCode, t }) => {
  const { key } = useLocation()

  const [modalOpen, setModalOpen] = useState(false)

  const { isBookConsultaionModalOpen, setIsConsultaionModalOpen } =
    useSharedContext()

  useEffect(() => {
    if (isBrowser) {
      window.bookConsultationRedirect = bookConsultationRedirect
    }
  }, [])

  useEffect(() => {
    setModalOpen(false)
  }, [key])

  useEffect(() => {
    const handleContextModalOpen = () => {
      setModalOpen(true)
      setIsConsultaionModalOpen(false)
    }

    if (!modalOpen && isBookConsultaionModalOpen) {
      handleContextModalOpen()
    }
  }, [modalOpen, isBookConsultaionModalOpen, setIsConsultaionModalOpen])

  const bookConsultationRedirect = response => {
    const {
      data: { firstname: firstName, lastname: lastName, zipcode: zipCode },
      response: id,
      type,
    } = response

    if (type !== "error") {
      const leadData = {
        id,
        firstName,
        lastName,
        zipCode,
      }

      const date = new Date()
      const expireTime = 60 * 45 * 1000
      date.setTime(date.getTime() + expireTime)

      CookieService.set("bf_lead_data", leadData, {
        path: "/",
        expires: date,
      })

      const { url } = getLanguage()
      const languageUrl =
        url === "/qc-fr"
          ? `${url}/merci`
          : url === "/us-es"
            ? `${url}/thank-you`
            : `${url}/thankyou`
      navigate(languageUrl)
    }
  }

  const triggerFormModal = () => {
    setModalOpen(!modalOpen)
  }

  const afterModalOpen = () => {
    document.body.style.overflow = "hidden"

    if (!widgets || !widgets.nodes) return

    widgets.nodes.forEach(widget => {
      if (
        (widget.language !== null &&
          widget.language !== formatToWpLanguage(languageCode)) ||
        widget.language === null
      ) {
        return
      }
    })
  }

  const afterModalClose = () => {
    document.body.style.overflow = "initial"
  }

  if (!widgets || !widgets.nodes) return null

  return widgets.nodes.map((widget, i) => {
    if (
      (widget.language !== null &&
        widget.language !== formatToWpLanguage(languageCode)) ||
      widget.language === null
    ) {
      return null
    }

    const { name, nf_assets } = widget

    return (
      <React.Fragment key={`${name}-${i}`}>
        <button
          className="Button Header-Button BookConsultation"
          onClick={triggerFormModal}
        >
          {t("header.bookConsultation")}
        </button>
        <ModalPopup
          isVisible={modalOpen}
          handleClose={triggerFormModal}
          modalOptions={{
            shouldCloseOnOverlayClick: true,
            style: {
              content: {
                top: "50%",
                left: "50%",
                right: "initial",
                bottom: "initial",
                overflow: "visible",
                borderRadius: "2px",
                padding: "15px",
              },
            },
          }}
          onAfterOpen={afterModalOpen}
          onAfterClose={afterModalClose}
        >
          <div className="CancelButtonRow">
            <Cancel onClick={triggerFormModal} className="CancelButton" />
          </div>
          <div className="CTAText Top">
            {t("header.bookConsultationTopCTA")}
          </div>
          <div className="CTAText Bottom">
            {t("header.bookConsultationBottomCTA")}
          </div>
          <div className="BookConsultationFormContainer">
            <NinjaForm nf_assets={nf_assets} />
          </div>
        </ModalPopup>
      </React.Fragment>
    )
  })
}

BookConsultation.propTypes = {
  widgets: PropTypes.objectOf(PropTypes.array),
  languageCode: PropTypes.string,
  t: PropTypes.func.isRequired,
}

BookConsultation.defaultProps = {
  widgets: {},
  languageCode: defaultLanguageCode,
}

export default withTranslation()(BookConsultation)

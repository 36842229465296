import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import InputField from "Components/InputField"
import SearchIcon from "Assets/Search.svg"
import { getLanguage } from "Utils/Language"

import "./BlogPostSearchInput.style.scss"

const BlogPostSearchInput = ({ placeholder }) => {
  const handleSubmit = value => {
    const { url } = getLanguage()
    const prefix = `${url}/blog/search/?s=`
    navigate(`${prefix}${value}`)
  }

  return (
    <div className="BlogPostSearchInput">
      <InputField placeholder={placeholder} onSubmit={handleSubmit} />
      <SearchIcon />
    </div>
  )
}

BlogPostSearchInput.propTypes = {
  placeholder: PropTypes.string,
}

BlogPostSearchInput.defaultProps = {
  placeholder: "",
}

export default BlogPostSearchInput

import React, { createContext, useContext, useEffect, useState } from "react"
import { getLanguage } from "Utils/Language"
import { getStore } from "Utils/WpRequests"
import CookieService from "Utils/CookieService"
import { validateZipCode } from "Utils/Validation"
import { useSharedContext } from "Src/contexts/SharedContext"
import axios from "axios"
import { getGeolocation } from "Utils/WpRequests"

export const LocationFinderContext = createContext()

const expireTime = 9 * 60 * 60 * 1000

export const LocationFinderProvider = ({ children }) => {
  const { suggestionCode } = useSharedContext()
  const [showPostalCodeEdit, setShowPostalCodeEdit] = useState(false)
  const [isLocationFinder, setIsLocationFinder] = useState(false)

  const [storeFound, setStoreFound] = useState(false)
  const [store, setStore] = useState(null)
  const [isStore, setIsStore] = useState(false)

  const [validationError, setValidationError] = useState("")
  const [notFoundError, setNotFoundError] = useState("")

  const [zipCode, setZipCode] = useState("")

  const onChange = e => setZipCode(e.target.value)

  const toggleLocationFinderPopup = () => setIsLocationFinder(prev => !prev)

  useEffect(() => {
    const locationData = CookieService.get("bf_location_data")
    if (!locationData) {
      const getIp = async () => {
        const ipAddress = await axios.get("https://api.ipify.org?format=json")
        const geo = await getGeolocation(ipAddress?.data?.ip, () => {})
        
        if (!geo?.postal?.code) return
        const res = await getStore({ zipCode: geo?.postal?.code,chatmeterToken:"",gbbisToken:""}, () => {})

        const date = new Date()
        date.setTime(date.getTime() + expireTime)

        const modifiedRes = {
          ...res,
          zipCode: geo?.postal?.code,
        }
        CookieService.set("bf_location_data", modifiedRes, {
          path: "/",
          expires: date,
        })

        const storeData = Object.keys(res)?.length > 1

        setZipCode(geo?.postal?.code)
        setNotFoundError("")
        setStore(res)
        setIsStore(true)
        if (storeData) setStoreFound(true)
      }

      getIp()

      return
    }

    const dataLength = Object.keys(locationData)?.length > 1

    setZipCode(locationData?.zipCode)
    setNotFoundError("")
    setStore(locationData)
    setIsStore(true)
    if (dataLength) setStoreFound(true)
  }, [])

  const fetchStore = async t => {
    const { language } = getLanguage()
    if (zipCode && !validateZipCode(zipCode, language, suggestionCode)) {
      setValidationError(t("locationFinder.validationError"))
      setNotFoundError("")
    } else {
      const forwardStationAreaLength = 3
      const canadianZipLength = 6
      const alternateZipCode =
        zipCode?.length === canadianZipLength
          ? {
              alternateZipCode: zipCode?.substring(0, forwardStationAreaLength),
            }
          : {}

      setValidationError("")

      try {
        const location_data = CookieService.get("bf_location_data")
        let cmToken = location_data?.chatmeterToken || "";
        let gbbisToken = location_data?.gbbisToken || "";
        const res = await getStore(
          { zipCode, chatmeterToken:cmToken,gbbisToken, ...alternateZipCode },
          () => {},
        )
        const date = new Date()
        date.setTime(date.getTime() + expireTime)

        const modifiedRes = {
          ...res,
          zipCode,
        }

        CookieService.set("bf_location_data", modifiedRes, {
          path: "/",
          expires: date,
        })

        if (res?.id) {
          setNotFoundError("")
          setStore(res)
          setIsStore(true)
          setStoreFound(true)
          setIsLocationFinder(true)
        } else {
          setNotFoundError(t("locationError.errorMessage"))
          setStore(null)
          setIsStore(true)
          setStoreFound(false)
          setIsLocationFinder(true)
        }
      } catch (error) {
        console.error("error: getting store data", error)
      }
    }
  }

  const contextValue = {
    showPostalCodeEdit,
    setShowPostalCodeEdit,

    store,

    isStore,
    setIsStore,

    storeFound,

    validationError,
    notFoundError,

    zipCode,
    setZipCode,
    onChange,

    fetchStore,

    isLocationFinder,
    setIsLocationFinder,
    toggleLocationFinderPopup,
  }

  return (
    <LocationFinderContext.Provider value={contextValue}>
      {children}
    </LocationFinderContext.Provider>
  )
}

export const useLocationFinderContext = () => useContext(LocationFinderContext)

import React from "react"
import PropTypes from "prop-types"
import {
  CarouselProvider,
  Slider,
  Slide,
  DotGroup,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import BlockTransition, {
  ElemsTransitionPropType,
  ElemsTransitionDefaultProps,
} from "Components/BlockTransition"
import { createReactFromNode } from "Utils/ReactFromNode"
import CheckIcon from "Assets/Check.svg"
import Arrow from "Assets/Arrow.svg"

const DidYouKnowSlider = ({ node, elemsTransition }) => {
  const getGrandchildValue = (child, firstChildrenIndex = 0) => {
    const {
      children: {
        [firstChildrenIndex]: { children },
      },
    } = child

    return createReactFromNode(children)
  }

  const getElements = () => {
    const {
      children,
      attribs: { class: className },
    } = node

    const elements = children.reduce(
      (acc, child, index) => {
        const {
          children,
          name,
          attribs: { class: className },
        } = child
        const { listMap } = acc

        if (!children.length) return acc

        if (name === "li") {
          const title = getGrandchildValue(child)

          if (title) {
            const text = getGrandchildValue(child, 1)

            const listSymbol =
              className === "isNumberList" ? (
                <span className="ListSymbol isNumber">{`${
                  Math.ceil(listMap.length) + 1
                }`}</span>
              ) : (
                <CheckIcon className="ListSymbol isCheckMark" />
              )

            acc.listMap.push(
              <li className={`ListItem ${className}`} key={index}>
                <p className="ListItemTitle">
                  {listSymbol}
                  {title}
                </p>
                <p className="ListItemText">{text}</p>
              </li>,
            )
          }
        }

        if (className === "Microcopy")
          acc.microcopy = createReactFromNode(child)
        if (className === "Title") acc.title = createReactFromNode(child)
        if (className === "LinkButton")
          acc.linkButton = createReactFromNode(child)

        return acc
      },
      {
        microcopy: "",
        title: "",
        linkButton: "",
        listMap: [],
        className,
      },
    )

    return elements
  }

  const renderSlider = (sliderMap, sliderMapLength, className) => {
    if (sliderMapLength === 1) {
      const { 0: listItems } = sliderMap

      return (
        <div className="ContentWithoutSlider">
          <ul className={className}>{listItems}</ul>
        </div>
      )
    }

    return (
      <Slider>
        {sliderMap.map((listItems, index) => (
          <Slide key={index}>
            <ul className={className}>{listItems}</ul>
          </Slide>
        ))}
      </Slider>
    )
  }

  const renderContent = () => {
    const { listMap, title, microcopy, linkButton, className } = getElements()

    const sliderMap = listMap.reduce((acc, item, index) => {
      const maxItems = 3
      const listIndex = parseInt(index / maxItems, 10)

      if (!acc[listIndex]) acc[listIndex] = []

      acc[listIndex].push(item)

      return acc
    }, [])

    const sliderMapLength = sliderMap.length

    return (
      <CarouselProvider totalSlides={sliderMapLength} infinite>
        {microcopy && <span>{microcopy}</span>}
        {title && title}
        {renderSlider(sliderMap, sliderMapLength, className)}
        {linkButton && <div className="LinkButtonWrapper">{linkButton}</div>}
        <div className="SliderControls">
          <ButtonBack>
            <Arrow className="CarouselArrow" />
          </ButtonBack>
          <DotGroup />
          <ButtonNext>
            <Arrow className="CarouselArrow" />
          </ButtonNext>
        </div>
      </CarouselProvider>
    )
  }

  return (
    <BlockTransition elemsTransition={elemsTransition}>
      <div className="TextBlockWrapper">{renderContent()}</div>
    </BlockTransition>
  )
}

DidYouKnowSlider.propTypes = {
  node: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  ).isRequired,
  elemsTransition: ElemsTransitionPropType,
}

DidYouKnowSlider.defaultProps = {
  elemsTransition: ElemsTransitionDefaultProps,
}

export default DidYouKnowSlider

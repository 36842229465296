import React from "react"
import PropTypes from "prop-types"
import { createReactFromNode } from "Utils/ReactFromNode"
import BeforeAndAfterPictureSlider from "./BeforeAndAfterPictureSlider.component"

const createSlideChildren = children => {
  return children.reduce(
    (acc, { children, name, attribs, attribs: { class: className } }) => {
      const classNameLowerCase = className.toLowerCase().split(" ")[0]
      acc[classNameLowerCase] = {
        attribs,
        name,
        value: (name !== "img" && createReactFromNode(children)) || undefined,
      }
      return acc
    },
    {},
  )
}

const createChildrenObject = nodeChildren => {
  const sliderMap = []
  const otherElementObject = {}

  nodeChildren.forEach(
    ({ attribs, attribs: { class: className }, children }) => {
      if (className === "Slide") {
        const subChildObject = createSlideChildren(children)
        if (Object.keys(subChildObject).length) sliderMap.push(subChildObject)
      } else {
        const data = createReactFromNode(children)
        if (data) otherElementObject[className] = { attribs, value: data }
      }
    },
  )

  return { sliderMap, otherElementObject }
}

const BeforeAndAfterPictureSliderWrapper = ({ node }) => {
  const { sliderMap, otherElementObject } = createChildrenObject(node.children)

  return (
    <div className="BeforeAndAfterPictureSlider">
      <BeforeAndAfterPictureSlider
        sliderMap={sliderMap}
        otherElementObject={otherElementObject}
      />
    </div>
  )
}

BeforeAndAfterPictureSliderWrapper.propTypes = {
  node: PropTypes.shape({
    children: PropTypes.arrayOf(
      PropTypes.shape({
        attribs: PropTypes.object.isRequired,
        name: PropTypes.string.isRequired,
        children: PropTypes.array.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
}

export default BeforeAndAfterPictureSliderWrapper

import React from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

import Link from "Components/Link"
import { getLanguage } from "Utils/Language"
import "./FAQBlock.style.scss"
import ProgressiveImage from "Components/ProgressiveImg"
import { getPreviewImageSrc } from "Utils/Helpers"
import FAQTab from "./FAQTab.component"

const FAQBlock = props => {
  const {
    className,
    microcopy,
    title,
    subTitle,
    description,
    imageUrl,
    tagsList,
    tabList,
    styleObject,
    t,
  } = props

  const renderTabList = () => {
    const maxTabsAllowed = 4

    return tabList.slice(0, maxTabsAllowed).map((tab, index) => {
      const { title, text } = tab

      return (
        <FAQTab
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          title={title}
          text={text}
        />
      )
    })
  }

  const composeFaqUrl = () => {
    const { url } = getLanguage()
    const tagsListUrlParams = tagsList.join(",")
    const urlParams = new URLSearchParams()
    urlParams.set("tags", tagsListUrlParams)

    return `${url}/faqs${tagsListUrlParams ? `?${urlParams}` : ""}`
  }

  const composeCtaTags = () => {
    const formattedTags = tagsList.map(tag => {
      const lowerCaseTag = tag.toLowerCase()

      return <strong key={lowerCaseTag}>{lowerCaseTag}</strong>
    })

    switch (tagsList.length) {
      case 0:
        return "our services"
      case 1:
        return formattedTags[0]
      case 2:
        return [formattedTags[0], " ", t("faqBlock.and"), " ", formattedTags[1]]
      default: {
        const lastTag = formattedTags[tagsList.length - 1]

        // Create new array that will include a comma after each element
        const newLength = formattedTags.length * 2 - 1
        const allTagsButLast = [...new Array(newLength)]
          .map((_, index) => {
            if (index === 0) return formattedTags[0]

            return index % 2 === 0 ? formattedTags[index / 2] : ", "
          })
          .slice(0, newLength - 1)

        return [...allTagsButLast, t("faqBlock.and"), " ", lastTag]
      }
    }
  }

  return (
    <section className={className} style={styleObject}>
      <div className="FAQBlocks-Wrapper">
        <div className="BlockImage">
          <ProgressiveImage
            src={getPreviewImageSrc(imageUrl)}
            largeSrc={imageUrl}
            alt="FAQ Block"
          />
        </div>
        <div className="Content">
          <h3 className="BlockMicrocopy">{microcopy}</h3>
          <h1 className="BlockTitle">{title}</h1>
          <h2 className="BlockSubtitle">{subTitle}</h2>
          <p className="BlockDescription">{description}</p>
          <div className="TabList">{renderTabList()}</div>
          <div className="Cta">
            {t("faqBlock.stillHaveQuestions")} {composeCtaTags()}
            {"?"} <Link to={composeFaqUrl()}>{t("faqBlock.visitTheFaq")}</Link>
          </div>
        </div>
      </div>
    </section>
  )
}

FAQBlock.propTypes = {
  className: PropTypes.string.isRequired,
  microcopy: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  tagsList: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  tabList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
  styleObject: PropTypes.objectOf(PropTypes.string).isRequired,
  t: PropTypes.func.isRequired,
}

export default withTranslation()(FAQBlock)

import React, { useMemo } from "react"
import PropTypes from "prop-types"
import moment from "moment"
import Dropdown from "Components/Dropdown"
import { isBrowser } from "Utils/Helpers"
import { languages } from "Utils/Language"
import CookieService from "Utils/CookieService"

const LocaleSelectorSelect = ({ children, navigateToTranslation }) => {
  const makeOptions = useMemo(() => {
    return children.map(child => {
      const { value, children: label } = child.props
      return {
        label,
        value,
        key: value,
        enabled: true,
      }
    })
  }, [children])

  const optionClicked = option => {
    const { value: langCode } = option
    const domainBF = process.env.GATSBY_FE_BF_URL.split("://")[1]
    const domainBM = process.env.GATSBY_FE_BM_URL.split("://")[1]

    CookieService.set("bf_locale", langCode, {
      path: "/",
      expires: moment().add(30, "days").toDate(),
      domain: domainBF,
    })
    CookieService.set("bf_locale", langCode, {
      path: "/",
      expires: moment().add(30, "days").toDate(),
      domain: domainBM,
    })

    isBrowser && document.body.classList.remove("noScroll")

    navigateToTranslation(languages[langCode])
  }

  const options = makeOptions.slice()
  const labelOption = options.shift()

  return (
    <Dropdown
      classname="wp-locale-selector-select"
      onOptionClick={optionClicked}
      options={options}
      placeholderText={labelOption.label}
    />
  )
}

LocaleSelectorSelect.propTypes = {
  children: PropTypes.node.isRequired,
  navigateToTranslation: PropTypes.func.isRequired,
}

export default LocaleSelectorSelect

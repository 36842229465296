import React from "react"
import ReactModal from "react-modal"
import PropTypes from "prop-types"

import "./ModalPopup.style.scss"

ReactModal.setAppElement("#___gatsby")

const blockName = "ModalPopup"

const ModalPopup = ({
  children,
  isVisible,
  handleClose,
  onAfterOpen,
  onAfterClose,
  modalOptions,
  showCloseButton,
  className,
}) => (
  <ReactModal
    overlayClassName={`${blockName} ${className}`}
    isOpen={isVisible}
    onRequestClose={() => handleClose()}
    onAfterOpen={() => onAfterOpen()}
    onAfterClose={() => onAfterClose()}
    style={{
      content: {
        top: "50%",
        left: "50%",
        right: "initial",
        bottom: "initial",
        overflow: "hidden",
        borderRadius: "2px",
        padding: "15px",
      },
    }}
    {...modalOptions}
  >
    <div className="ContentWrapper">{children}</div>
    {showCloseButton && (
      <button onClick={() => handleClose()} className="CloseButton">
        Close
      </button>
    )}
  </ReactModal>
)

ModalPopup.propTypes = {
  children: PropTypes.node.isRequired,
  isVisible: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  onAfterOpen: PropTypes.func,
  onAfterClose: PropTypes.func,
  modalOptions: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.object]),
  ),
  showCloseButton: PropTypes.bool,
  className: PropTypes.string,
}

ModalPopup.defaultProps = {
  modalOptions: {},
  showCloseButton: false,
  className: "",
  handleClose: () => {},
  onAfterOpen: () => {
    document.body.style.overflow = "hidden"
  },
  onAfterClose: () => {
    document.body.style.overflow = "initial"
  },
}

export default ModalPopup

import { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { parseJson } from "Utils/Validation"

const TagsWrapper = ({ children, activePageTag }) => {
  const [activeChildren, setActiveChildren] = useState(null)

  useEffect(() => {
    const { id: activeId } = activePageTag
    const foundChild = children.find(
      ({ props: { data } }) => parseJson(data, {}).id === activeId,
    )
    setActiveChildren(foundChild)
  }, [children, activePageTag])

  return activeChildren
}

TagsWrapper.propTypes = {
  children: PropTypes.arrayOf(PropTypes.object).isRequired,
  activePageTag: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  ).isRequired,
}

export default TagsWrapper

import React from "react"
import PropTypes from "prop-types"
import BlogPostCard from "Components/BlogPostCard"

import "./BlogPostLatestPosts.style.scss"

const BlogPostLatestPosts = ({ title, postMap }) => {
  const renderSinglePost = post => {
    const { id } = post

    return (
      <div className="SinglePost" key={id}>
        <BlogPostCard post={post} showedElems={["image", "title"]} />
      </div>
    )
  }

  const renderTitle = () => {
    if (!title) {
      return null
    }

    return <h2 className="BlockTitle">{title}</h2>
  }

  return (
    <div className="BlogPostLatestPosts">
      {renderTitle()}
      {postMap.map(renderSinglePost)}
    </div>
  )
}

BlogPostLatestPosts.propTypes = {
  postMap: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
}

export default BlogPostLatestPosts

import React from "react"
import PropTypes from "prop-types"
import ShareButtonGroup from "Components/ShareButtonGroup"
import BlogPostSearchInput from "Components/BlogPostSearchInput"
import { getGenericShareImage } from "Utils/Helpers"

import "./BlogPostTitleSection.style.scss"

const BlogPostTitleSection = ({
  blockTitle,
  searchPlaceholder,
  metaTitle,
  metaDescription,
  metaImage,
}) => {
  const shareTitle = blockTitle || metaTitle
  // eslint-disable-next-line max-len
  const shareImage =
    getGenericShareImage() ||
    (typeof metaImage === "string" ? metaImage.replace("-preview", "") : "")

  return (
    <div className="BlogPostTitleSection">
      <h2 className="Title">{blockTitle}</h2>
      <div className="ContentWrapper">
        <BlogPostSearchInput placeholder={searchPlaceholder} />
        <ShareButtonGroup
          title={shareTitle}
          description={metaDescription}
          featuredMediaSrc={shareImage}
        />
      </div>
    </div>
  )
}

BlogPostTitleSection.propTypes = {
  blockTitle: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
  metaImage: PropTypes.string,
}

BlogPostTitleSection.defaultProps = {
  blockTitle: "",
  searchPlaceholder: "",
  metaTitle: "",
  metaDescription: "",
  metaImage: "",
}

export default BlogPostTitleSection

import React from "react"
import PropTypes from "prop-types"
import { ElemsTransitionPropType } from "Components/BlockTransition"
import Video from "./Video.component"

const VideoContainer = ({
  videoUrl = "",
  posterUrl = "",
  microcopy = "",
  heading = "",
  subHeading = "",
  text = "",
  videoHeight = "",
  videoIsAutoPlay = false,
  videoIsLoop = false,
  videoPlayButtonText = "",
  videoPlayButtonId = "default",
  videoWidthId = "default",
  videoIsMute = false,
  videoIsContentVisibleOnPlay = false,
  videoOverlayIsEnabled = false,
  videoOverlayColor = { hex: "", opacity: 1, rgb: {} },
  contentAlignment = "left",
  button = "",
  videoStyle = "",
  elemsTransition,
}) => {
  const content = {
    microcopy,
    heading,
    subHeading,
    text,
    button,
  }

  return (
    <Video
      videoUrl={videoUrl}
      posterUrl={posterUrl}
      content={content}
      videoPlayButtonText={videoPlayButtonText}
      videoHeight={videoHeight}
      videoIsAutoPlay={videoIsAutoPlay}
      videoIsLoop={videoIsLoop}
      videoIsMute={videoIsMute}
      videoPlayButtonId={videoPlayButtonId}
      videoIsContentVisibleOnPlay={videoIsContentVisibleOnPlay}
      contentAlignment={contentAlignment}
      videoOverlayIsEnabled={videoOverlayIsEnabled}
      videoOverlayColor={videoOverlayColor}
      videoWidthId={videoWidthId}
      videoStyle={videoStyle}
      elemsTransition={elemsTransition}
    />
  )
}

VideoContainer.propTypes = {
  videoUrl: PropTypes.string,
  posterUrl: PropTypes.string,
  videoPlayButtonId: PropTypes.string,
  videoPlayButtonText: PropTypes.string,
  videoWidthId: PropTypes.string,
  videoHeight: PropTypes.string,
  videoIsAutoPlay: PropTypes.bool,
  videoIsLoop: PropTypes.bool,
  videoIsMute: PropTypes.bool,
  videoOverlayIsEnabled: PropTypes.bool,
  videoOverlayColor: PropTypes.shape({
    hex: PropTypes.string,
    opacity: PropTypes.number,
    rgb: PropTypes.object,
  }),
  videoIsContentVisibleOnPlay: PropTypes.bool,
  contentAlignment: PropTypes.string,
  microcopy: PropTypes.string,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  button: PropTypes.string,
  videoStyle: PropTypes.string,
  elemsTransition: ElemsTransitionPropType.isRequired,
}

VideoContainer.defaultProps = {
  videoUrl: "",
  posterUrl: "",
  videoPlayButtonId: "default",
  videoPlayButtonText: "",
  videoWidthId: "default",
  videoHeight: "",
  videoIsAutoPlay: false,
  videoIsLoop: false,
  videoIsMute: false,
  videoOverlayIsEnabled: false,
  videoOverlayColor: { hex: "", opacity: 1, rgb: {} },
  videoIsContentVisibleOnPlay: false,
  contentAlignment: "left",
  microcopy: "",
  heading: "",
  subHeading: "",
  text: "",
  className: "",
  button: "",
  videoStyle: "",
}

export default VideoContainer

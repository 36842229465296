import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"

const ProgressiveImage = ({
  src: lowResolutionSrc,
  largeSrc: highResolutionSrc,
  className = "",
  setRef,
  ...rest
}) => {
  const hasFullResolutionSrc = (lowResSrc, highResSrc) => {
    return highResSrc && lowResSrc !== highResSrc
  }

  const localImageRef = useRef()
  const [src, setSrc] = useState(lowResolutionSrc)
  const [imageCssClass, setImageCssClass] = useState(
    hasFullResolutionSrc(lowResolutionSrc, highResolutionSrc)
      ? "ImagePreview"
      : "ImageRevealed",
  )

  useEffect(() => {
    const handleReveal = () => {
      if (src === highResolutionSrc) {
        setImageCssClass("ImageRevealed")
      }
    }

    const loadFullImage = (lowResSrc, highResSrc) => {
      if (isCached(highResSrc)) return

      setSrc(lowResSrc)
      setImageCssClass("ImagePreview")

      const img = new Image()
      img.onload = () => {
        if (localImageRef.current) {
          localImageRef.current.onload = handleReveal
        }
        setSrc(highResSrc)
      }
      img.src = highResSrc
    }

    if (hasFullResolutionSrc(lowResolutionSrc, highResolutionSrc)) {
      loadFullImage(lowResolutionSrc, highResolutionSrc)
    }
  }, [lowResolutionSrc, highResolutionSrc, src])

  const mergeRefs = (...refs) => {
    const filteredRefs = refs.filter(Boolean)

    if (!filteredRefs.length) return null

    if (filteredRefs.length === 1) return filteredRefs[0]

    return inst => {
      filteredRefs.forEach(ref => {
        if (typeof ref === "function") {
          ref(inst)
        } else if (ref) {
          ref.current = inst
        }
      })
    }
  }

  const isCached = src => {
    const img = new Image()
    img.src = src

    if (img.complete) {
      setSrc(src)
      setImageCssClass("ImageRevealed")
      img.src = ""
      return true
    }
    return false
  }

  return (
    <img
      src={src}
      className={`${className.replace("ImagePreview", "")} ${imageCssClass}`}
      ref={mergeRefs(localImageRef, setRef)}
      {...rest}
      alt=""
    />
  )
}

ProgressiveImage.propTypes = {
  src: PropTypes.string.isRequired,
  largeSrc: PropTypes.string.isRequired,
  className: PropTypes.string,
  setRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
}

export default ProgressiveImage

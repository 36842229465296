import React, { useEffect, useState, useRef, useMemo, useCallback } from "react"
import PropTypes from "prop-types"
import ReactSlider from "react-slider"
import "./BeforeAndAfterPicture.style.scss"
import ProgressiveImage from "Components/ProgressiveImg"
import { useSharedContext } from "Src/contexts/SharedContext"

const MAX_SLIDER_VALUE = 1000

const BeforeAndAfterPicture = ({
  sliderMap,
  otherElementObject,
  sliderWrapperPadding,
  beforeImageSrc,
  beforeImageLargeSrc,
  afterImageSrc,
  afterImageLargeSrc,
  beforeLabelValue,
  afterLabelValue,
}) => {
  const { isMobile } = useSharedContext()

  const [previousIsMobile, setPreviousIsMobile] = useState(null)
  const sliderRef = useRef(null)
  const sliderTopImage = useRef(null)

  const calculateClipRect = useMemo(() => {
    const sliderWidth = isMobile ? "100vw" : "55vw"
    const sliderWidthMax = "750px"
    const finalSliderWidth = isMobile
      ? sliderWidth
      : `min(${sliderWidth}, ${sliderWidthMax})`

    const minusValues = sliderWrapperPadding ? `${sliderWrapperPadding}px` : 0

    return value =>
      `rect(0px, calc(((${finalSliderWidth} - ${minusValues}) / (${MAX_SLIDER_VALUE}) * ${value})), 1000px, 0px)`
  }, [isMobile, sliderWrapperPadding])

  const handleSliderChange = useCallback(
    value => {
      if (sliderTopImage.current) {
        sliderTopImage.current.style.clip = calculateClipRect(value)
      }
    },
    [calculateClipRect],
  )

  useEffect(() => {
    const currentSliderValue = sliderRef.current.state.value[0]

    if (!previousIsMobile && isMobile) handleSliderChange(MAX_SLIDER_VALUE / 2)
    if (isMobile !== previousIsMobile) handleSliderChange(currentSliderValue)

    setPreviousIsMobile(isMobile)
  }, [isMobile, previousIsMobile, handleSliderChange])

  const renderThumb = props => (
    <span {...props} className="SliderThumbWrapper">
      <span className="SliderThumb" />
    </span>
  )

  return (
    <div className="BeforeAndAfterPicture">
      <div className="ContentWrapper">
        <div className="SliderWrapper">
          <ReactSlider
            className="Slider"
            ref={sliderRef}
            max={MAX_SLIDER_VALUE}
            defaultValue={MAX_SLIDER_VALUE / 2}
            onChange={handleSliderChange}
            renderThumb={renderThumb}
          />
          <ProgressiveImage
            className="SlideImage"
            src={beforeImageSrc}
            largeSrc={beforeImageLargeSrc}
            alt="Before"
          />
          <ProgressiveImage
            className="SlideImage"
            src={afterImageSrc}
            largeSrc={afterImageLargeSrc}
            alt="After"
            setRef={sliderTopImage}
          />
          <span className="ImageLabel">{beforeLabelValue}</span>
          <span className="ImageLabel After">{afterLabelValue}</span>
        </div>
      </div>
    </div>
  )
}

BeforeAndAfterPicture.propTypes = {
  sliderMap: PropTypes.arrayOf(PropTypes.object).isRequired,
  otherElementObject: PropTypes.objectOf(PropTypes.object).isRequired,
  sliderWrapperPadding: PropTypes.number,
  beforeImageSrc: PropTypes.string.isRequired,
  beforeImageLargeSrc: PropTypes.string.isRequired,
  afterImageSrc: PropTypes.string.isRequired,
  afterImageLargeSrc: PropTypes.string.isRequired,
  beforeLabelValue: PropTypes.string.isRequired,
  afterLabelValue: PropTypes.string.isRequired,
}

BeforeAndAfterPicture.defaultProps = {
  sliderWrapperPadding: 0,
}

export default BeforeAndAfterPicture

import React from "react"
import PropTypes from "prop-types"
import { getLanguage } from "Utils/Language"
import BlogPostLatestPosts from "./BlogPostLatestPosts.component"

const BlogPostLatestPostsContainer = ({ posts, title }) => {
  const POST_COUNT = 10
  const postMap = posts.recent_posts
    .filter(post => post.lang === getLanguage().url.replace("/", ""))
    .slice(0, POST_COUNT)

  return <BlogPostLatestPosts postMap={postMap} title={title} />
}

BlogPostLatestPostsContainer.propTypes = {
  posts: PropTypes.shape({
    recent_posts: PropTypes.arrayOf(PropTypes.object),
  }),
  title: PropTypes.string,
}

BlogPostLatestPostsContainer.defaultProps = {
  posts: {},
  title: "",
}

export default BlogPostLatestPostsContainer

import React from "react"
import PropTypes from "prop-types"
import {
  CarouselProvider,
  Slider,
  Slide,
  DotGroup,
  ButtonNext,
  ButtonBack,
} from "pure-react-carousel"
import Link from "Components/Link"
import { BlogPostCardCategories } from "Components/BlogPostCard"
import { htmlToReact } from "Utils/HtmlParser"
import "./BlogPostFeaturedListingSlider.style.scss"
import ProgressiveImage from "Components/ProgressiveImg"
import { getPreviewImageSrc } from "Utils/Helpers"

const BlogPostFeaturedListingSlider = ({
  sliderMap,
  badgeImageSrc,
  buttonName,
  buttonId,
  backgroundColor,
}) => {
  const renderArrow = isNext => {
    const isSingleSlide = sliderMap.length === 1
    const Tag = isNext ? ButtonNext : ButtonBack
    const isNextArrow = isNext ? "isNext" : ""

    if (isSingleSlide) return null

    return <Tag className={`NoButtonStyle ArrowButton ${isNextArrow}`} />
  }

  const renderCategories = categories => {
    return <BlogPostCardCategories categories={categories} />
  }

  const renderSingleSlide = (
    { imageUrl, categories, title, description, postHref },
    index,
  ) => {
    const buttonClass =
      buttonId === "withBackground" ? "WithBackground" : "Primary"

    return (
      <Slide key={index} className="SliderSlide">
        <div className="SliderContent">
          {imageUrl && (
            <div className="ImageWrapper">
              <div className="ImageContainer">
                <ProgressiveImage
                  src={getPreviewImageSrc(imageUrl)}
                  largeSrc={imageUrl}
                  alt="Featured"
                  className="Image"
                />
              </div>
              {badgeImageSrc && (
                <ProgressiveImage
                  src={getPreviewImageSrc(badgeImageSrc)}
                  largeSrc={badgeImageSrc}
                  className="BadgeImage"
                  alt="Badge"
                />
              )}
            </div>
          )}
          <div className="RightSideWrapper">
            {renderCategories(categories)}
            {title && <h2 className="Title">{htmlToReact(title)}</h2>}
            {description && <p className="Text">{htmlToReact(description)}</p>}
            {buttonName && (
              <div className="ButtonWrapper">
                <Link
                  to={postHref}
                  className={`Button Button-Secondary ${buttonClass}`}
                >
                  {htmlToReact(buttonName)}
                </Link>
              </div>
            )}
          </div>
        </div>
      </Slide>
    )
  }

  return (
    <div className="BlogPostFeaturedListingSlider" style={{ backgroundColor }}>
      <div className="ContentWrapper">
        <CarouselProvider
          totalSlides={sliderMap.length}
          infinite
          className="Carousel"
        >
          {renderArrow()}
          <Slider className="Slider">{sliderMap.map(renderSingleSlide)}</Slider>
          <DotGroup className="Dots" />
          {renderArrow(true)}
        </CarouselProvider>
      </div>
    </div>
  )
}

BlogPostFeaturedListingSlider.propTypes = {
  sliderMap: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ).isRequired,
  badgeImageSrc: PropTypes.string.isRequired,
  buttonName: PropTypes.string.isRequired,
  buttonId: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
}

export default BlogPostFeaturedListingSlider

import React, { useCallback } from "react"
import PropTypes from "prop-types"
import { useSharedContext } from "Src/contexts/SharedContext"
import { createReactFromNode } from "Utils/ReactFromNode"

const BookingConsultationBlockButton = ({ node }) => {
  const { setIsConsultaionModalOpen } = useSharedContext()

  const handleButtonClick = useCallback(() => {
    setIsConsultaionModalOpen(true)
  }, [setIsConsultaionModalOpen])

  return (
    <button className="PrimaryButton" onClick={handleButtonClick}>
      {createReactFromNode(node.children)}
    </button>
  )
}

BookingConsultationBlockButton.propTypes = {
  node: PropTypes.shape({
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.array,
    ]),
  }).isRequired,
}

export default BookingConsultationBlockButton

import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"

const LocaleSwitcher = ({ children }) => children

LocaleSwitcher.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withTranslation()(LocaleSwitcher)

import React from "react"
import PropTypes from "prop-types"
import BlockTransition from "./BlockTransition.component"

const BlockTransitionContainer = ({
  children,
  blockTransition = {},
  elemsTransition = [],
}) => {
  return (
    <BlockTransition
      blockTransition={blockTransition}
      elemsTransition={elemsTransition}
    >
      {children}
    </BlockTransition>
  )
}

BlockTransitionContainer.propTypes = {
  children: PropTypes.node.isRequired,
  blockTransition: PropTypes.object,
  elemsTransition: PropTypes.arrayOf(PropTypes.object),
}

export default BlockTransitionContainer

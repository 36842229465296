import React from "react"
import PropTypes from "prop-types"
import { getStyleObjectFromString } from "Utils/Helpers"
import FAQBlocks from "./FAQBlock.component"

const FAQBlockContainer = ({ node }) => {
  const {
    attribs: {
      class: className = "",
      imageurl: imageUrl = "",
      microcopy = "",
      description = "",
      title = "",
      subtitle = "",
      tagslist: tagsList = "",
      tablist: tabList = "",
      style = "",
    },
  } = node

  const parseJson = (json, defaultValue = null) => {
    try {
      const parsedJson = JSON.parse(json)

      return parsedJson
    } catch {
      return defaultValue
    }
  }

  const tagsListParsed = parseJson(tagsList, [])
  const tabListParsed = parseJson(tabList, [])

  return (
    <FAQBlocks
      className={className}
      microcopy={microcopy}
      title={title}
      subTitle={subtitle}
      description={description}
      imageUrl={imageUrl}
      tagsList={tagsListParsed}
      tabList={tabListParsed}
      styleObject={getStyleObjectFromString(style)}
    />
  )
}

FAQBlockContainer.propTypes = {
  node: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  ).isRequired,
}

export default FAQBlockContainer

import React from "react"
import PropTypes from "prop-types"
import BlogPostHeader from "./BlogPostHeader.component"

const BlogPostHeaderContainer = ({
  title,
  categories,
  excerpt,
  featured_media,
}) => {
  return (
    <BlogPostHeader
      categories={categories}
      featuredMediaSrc={featured_media?.source_url || ""}
      title={title}
      description={excerpt}
    />
  )
}

BlogPostHeaderContainer.propTypes = {
  title: PropTypes.string,
  excerpt: PropTypes.string,
  featured_media: PropTypes.shape({
    source_url: PropTypes.string,
  }),
  categories: PropTypes.arrayOf(PropTypes.object),
}

BlogPostHeaderContainer.defaultProps = {
  title: "",
  excerpt: "",
  featured_media: {},
  categories: [{}],
}

export default BlogPostHeaderContainer

import React from "react"
import PropTypes from "prop-types"
import {
  FacebookShareButton,
  PinterestShareButton,
  EmailShareButton,
  TwitterShareButton,
} from "react-share"
import EmailIcon from "Assets/Email.svg"
import FacebookIcon from "Assets/Facebook.svg"
import PinterestIcon from "Assets/Pinterest.svg"
import TwitterIcon from "Assets/Twitter.svg"
import "./ShareButton.style.scss"

const ShareButton = ({
  type,
  url,
  media,
  subject,
  body,
  twitterTitle,
  description,
}) => {
  const renderTwitterButton = () => (
    <TwitterShareButton
      url={url}
      className="ShareButtonContent NoButtonStyle"
      title={twitterTitle}
    >
      <TwitterIcon />
    </TwitterShareButton>
  )

  const renderFacebookButton = () => (
    <FacebookShareButton url={url} className="ShareButtonContent NoButtonStyle">
      <FacebookIcon />
    </FacebookShareButton>
  )

  const renderPinterestButton = () => (
    <PinterestShareButton
      url={url}
      media={media}
      description={description}
      className="ShareButtonContent NoButtonStyle"
    >
      <PinterestIcon />
    </PinterestShareButton>
  )

  const renderEmailButton = () => {
    const emailBody = body ? `${subject} - ${body}` : subject
    return (
      <EmailShareButton
        url={url}
        className="ShareButtonContent NoButtonStyle"
        subject={subject}
        body={emailBody}
        separator=" - "
      >
        <EmailIcon />
      </EmailShareButton>
    )
  }

  const buttonMap = {
    email: () => renderEmailButton(),
    facebook: () => renderFacebookButton(),
    pinterest: () => renderPinterestButton(),
    twitter: () => renderTwitterButton(),
  }

  const renderButton = () => buttonMap[type]()

  return <div className="ShareButton">{renderButton()}</div>
}

ShareButton.propTypes = {
  type: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  media: PropTypes.string,
  subject: PropTypes.string,
  body: PropTypes.string,
  twitterTitle: PropTypes.string,
  description: PropTypes.string,
}

ShareButton.defaultProps = {
  media: "",
  subject: "",
  body: "",
  twitterTitle: "",
  description: "",
}

export default ShareButton

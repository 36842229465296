import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import Link from "Components/Link"
import Search from "Components/Search"
import LocationFinder from "Components/LocationFinder"
import NavigationMenu from "Components/NavigationMenu"
import BookConsultation from "Components/BookConsultation"
import BookB2BConsultation from "Components/BookB2BConsultation"
import { hasSidebarForLocale, renderSidebarWidgets } from "Utils/Sidebars"
import ModalEuPopup from "Components/ModalEuPopup"

import "./Header.style.scss"

const Header = ({ isCSPage, hasPageHeader, data, language }) => {
  const headerTopRef = useRef(null)
  const headerRef = useRef(null)
  const headerAnnouncementRef = useRef(null)
  const [isActive, setIsActive] = useState(true)
  const [isSticky, setIsSticky] = useState(false)

  const {
    phoneNumber,
    commercialSalesPhone,
    headerAnnouncement,
    commercialSalesAnnouncement,

    commercialMenus,
    allMenus,
    logo,
    bookConsultationForm,
    bookConsultationB2BForm,
  } = data || {}

  useEffect(() => {
    const handleScroll = () => {
      const headerTop = headerTopRef.current
      const headerFull = headerRef.current
      const announcementOffsetHeight =
        headerAnnouncementRef?.current?.offsetHeight

      if (!headerTop || !headerFull) return

      const { top } = headerTop.getBoundingClientRect()
      const { pageYOffset } = window

      if (top <= 0) {
        if (top === 0 && pageYOffset <= announcementOffsetHeight) {
          const { pathname } = window.location
          if (
            pathname.split("/")[2] !== "chatmeter-template" &&
            pathname.split("/")[2] !== "location"
          ) {
            setIsSticky(false)
          } else {
            setIsSticky(true)
          }
        } else {
          setIsSticky(true)
        }
      }
    }

    window.addEventListener("scroll", handleScroll)
    window.addEventListener("load", handleOpenModal)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const handleOpenModal = () => {
    // Handle modal opening
  }

  const renderAnnouncementBanner = () => {
    const outputAnnouncement =
      isActive &&
      hasSidebarForLocale(
        isCSPage ? commercialSalesAnnouncement : headerAnnouncement,
        language,
      )

    return outputAnnouncement ? (
      <div className="Header-Announcement-Wrapper">
        <div className="Header-Announcement">
          <div className="Announcement-Content">
            {renderSidebarWidgets(
              isCSPage ? commercialSalesAnnouncement : headerAnnouncement,
              language,
            )}
          </div>

          <button
            className="Close-Button"
            aria-label="close"
            onClick={() => setIsActive(!isActive)}
          />
        </div>
      </div>
    ) : null
  }

  const renderPhoneNumber = () => {
    if (isCSPage) {
      commercialSalesPhone.nodes[0].rendered =
        commercialSalesPhone.nodes[2].rendered
      commercialSalesPhone.nodes[1].rendered =
        commercialSalesPhone.nodes[2].rendered
      return (
        <div className="Header-PhoneNumberWrapper">
          {renderSidebarWidgets(commercialSalesPhone, language)}
        </div>
      )
    }

    return (
      <div className="Header-PhoneNumberWrapper">
        {renderSidebarWidgets(phoneNumber, language)}
      </div>
    )
  }

  const renderMainWrapper = () => {
    return (
      <div className="Header-Main-Wrapper">
        <div className="Header-Main">
          <Link className="Header-Logo" to="/">
            {renderSidebarWidgets(logo, language)}
          </Link>
          {isCSPage ? (
            <>
              <div className="Header-Menu">
                <NavigationMenu
                  allMenus={commercialMenus}
                  logo={logo}
                  languageCode={language}
                />
              </div>
              <BookB2BConsultation
                widgets={bookConsultationB2BForm}
                languageCode={language}
              />
              <div className="CommercialSalesPhone"></div>
            </>
          ) : (
            <>
              <div className="Header-Menu">
                <NavigationMenu
                  allMenus={allMenus}
                  logo={logo}
                  languageCode={language}
                />
              </div>
              <BookConsultation
                widgets={bookConsultationForm}
                languageCode={language}
              />
            </>
          )}
        </div>
      </div>
    )
  }

  if (!hasPageHeader) return null

  return (
    <header
      className={`Header${isSticky ? " isSticky" : ""}${
        isCSPage ? " isCSPage" : ""
      }`}
      ref={headerRef}
    >
      <div
        className="Header-Announcement-Placeholder"
        ref={headerAnnouncementRef}
      >
        {renderAnnouncementBanner()}
      </div>
      <div className="Header-Wrapper">
        <div className="Header-Top-Wrapper" ref={headerTopRef}>
          <div className="Header-Top">
            <div className="Align-Left">
              <LocationFinder />
            </div>
            <div className="Header-Top-Align-Right-Wrapper">
              {renderPhoneNumber()}
              <Search />
            </div>
          </div>
        </div>
        {renderMainWrapper()}
        <ModalEuPopup></ModalEuPopup>
      </div>
    </header>
  )
}

Header.propTypes = {
  isCSPage: PropTypes.bool.isRequired,
  hasPageHeader: PropTypes.bool.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  language: PropTypes.string.isRequired,
}

export default Header

import React, {
  createContext,
  useContext,
  useCallback,
  useReducer,
  useEffect,
} from "react"
import { isBrowser } from "Utils/Helpers"
import { languages, getLanguage } from "Utils/Language"
import i18next from "i18next"
import { gtmFormEventPush } from "Utils/Gtm"
import { useLocation } from "@reach/router"

export const SharedContext = createContext()

const initialState = {
  hasPageHeader: true,
  hasPageFooter: true,
  translationSlugs: {},
  locationFinder: {
    isOpen: false,
    zipCode: "",
    locationZipCode: "",
  },
  isBookConsultaionModalOpen: false,
  activePageCategory: {
    id: null,
    name: "",
  },
  location: {},
  pathName: null,
  isMobile: null,
  isDesktop: null,
}

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_IS_MOBILE_DESKTOP":
      return {
        ...state,
        isMobile: action.payload.isMobile,
        isDesktop: action.payload.isDesktop,
      }

    case "SET_PATH_NAME":
      return {
        ...state,
        pathName: action.payload,
        hasPageHeader: true,
        hasPageFooter: true,
      }

    case "TOGGLE_BOOK_CONSULTATION_MODAL":
      return { ...state, isBookConsultaionModalOpen: action.payload }

    case "SET_TRANSLATION_SLUGS":
      return {
        ...state,
        translationSlugs: action.payload.translationSlugs,
      }
    case "SET_HAS_PAGE_HEADER":
      return {
        ...state,
        hasPageHeader: action.payload,
      }
    case "SET_HAS_PAGE_FOOTER":
      return {
        ...state,
        hasPageFooter: action.payload,
      }
    case "SET_IS_BOOK_CONSULTATION_MODAL_OPEN":
      return {
        ...state,
        isBookConsultaionModalOpen: action.payload,
      }
    case "SET_LOCATION":
      return {
        ...state,
        location: action.payload,
      }
    case "SET_ACTIVE_PAGE_CATEGORY":
      return {
        ...state,
        activePageCategory: {
          ...state.activePageCategory,
          ...action.payload,
        },
      }
    case "SET_LOCATION_FINDER":
      return {
        ...state,
        locationFinder: {
          ...state.locationFinder,
          ...action.payload,
        },
      }
    default:
      return state
  }
}

export const SharedContextProvider = ({
  children,
  suggestionCode,
  suggestedPostalCode,
  isSuggestionCodeDefault,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const locationUrl = useLocation()

  useEffect(() => {
    const { pathname: newPathName } = locationUrl
    const { pathName } = state

    if (newPathName !== pathName) {
      const newState = { type: "SET_PATH_NAME", payload: newPathName }

      if (typeof window !== "undefined") {
        const urlParams = new URLSearchParams(window.location.search)

        if (urlParams.has("form")) {
          newState.isBookConsultaionModalOpen = true
          dispatch({ type: "TOGGLE_BOOK_CONSULTATION_MODAL", payload: true })
        }
      }

      dispatch(newState)
    }
  }, [locationUrl, state.pathName])

  useEffect(() => {
    const { locale } = getLanguage()
    if (!isBrowser) return

    window.Parsley.setLocale(locale)

    const mobileWidth = 768
    const desktopWidth = 1221
    const isMobile = window.innerWidth < mobileWidth
    const isDesktop = window.innerWidth >= desktopWidth

    dispatch({
      type: "SET_IS_MOBILE_DESKTOP",
      payload: { isMobile, isDesktop },
    })

    const handleResize = () => {
      const isMobile = window.innerWidth < mobileWidth
      const isDesktop = window.innerWidth >= desktopWidth

      dispatch({
        type: "SET_IS_MOBILE_DESKTOP",
        payload: { isMobile, isDesktop },
      })
    }

    window.addEventListener("resize", handleResize)

    i18next.on("languageChanged", lng => {
      const { locale } = languages[lng]
      window.Parsley.setLocale(locale)
    })

    window.gtmFormEventPush = gtmFormEventPush
    // Cleanup function for the effect
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const getTranslationUrl = lng => {
    const { translationSlugs } = state
    const { base, url, code: language } = lng || getLanguage()
    const translationSlug = translationSlugs?.[language] ?? ""

    return `${base}${url}/${addTrailingSlash(translationSlug)}`
  }

  const getActivePageCategory = () => {
    const { activePageCategory } = state

    const change = activePageCategory => {
      dispatch({
        type: "SET_ACTIVE_PAGE_CATEGORY",
        payload: activePageCategory,
      })
    }

    return {
      activePageCategory: {
        ...activePageCategory,
        change,
      },
    }
  }

  const addTrailingSlash = path => {
    if (!path) return ""

    return path?.endsWith("/") ? path : `${path}/`
  }

  const navigateToTranslation = lng => {
    if (!isBrowser) return

    window.location = getTranslationUrl(lng)
  }

  const {
    hasPageHeader,
    hasPageFooter,
    pathName,
    isMobile,
    isDesktop,
    translationSlugs,
    locationFinder,
    isBookConsultaionModalOpen,
    location,
  } = state

  if (isBrowser) {
    if (!hasPageHeader) document.body.classList.add("NoHeader")
    else document.body.classList.remove("NoHeader")

    if (!hasPageFooter) document.body.classList.add("NoFooter")
    else document.body.classList.remove("NoFooter")

    locationFinder.locationZipCode = suggestedPostalCode
  }

  const locationfinder = {
    ...locationFinder,
    changeState: newState => {
      dispatch({
        type: "SET_LOCATION_FINDER",
        payload: newState,
      })
    },
  }

  const setTranslationSlugs = useCallback(
    value => dispatch({ type: "SET_TRANSLATION_SLUGS", payload: value }),
    [],
  )

  const setHasPageFooter = useCallback(
    value => dispatch({ type: "SET_HAS_PAGE_FOOTER", payload: value }),
    [],
  )

  const setHasPageHeader = useCallback(
    value => dispatch({ type: "SET_HAS_PAGE_HEADER", payload: value }),
    [],
  )

  const setIsConsultaionModalOpen = useCallback(
    value =>
      dispatch({ type: "SET_IS_BOOK_CONSULTATION_MODAL_OPEN", payload: value }),
    [],
  )
  const setLocation = useCallback(
    location => dispatch({ type: "SET_LOCATION", payload: location }),
    [],
  )

  const contextValue = {
    pathName,
    hasPageHeader,
    hasPageFooter,
    suggestionCode,
    isSuggestionCodeDefault,
    isMobile,
    isDesktop,
    translationSlugs,
    navigateToTranslation,
    getTranslationUrl,
    locationFinder: locationfinder,
    isBookConsultaionModalOpen,

    ...getActivePageCategory(),

    location,

    setIsConsultaionModalOpen,
    setTranslationSlugs,
    setHasPageFooter,
    setHasPageHeader,
    setLocation,
  }

  return (
    <SharedContext.Provider value={contextValue}>
      {children}
    </SharedContext.Provider>
  )
}

export const useSharedContext = () => useContext(SharedContext)

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { getMostViewedPostIds } from "Utils/WpRequests"
import { formatToWpLanguage, getLanguage } from "Utils/Language"

import BlogPostMostPopularPosts from "./BlogPostMostPopularPosts.component"

const POST_COUNT = 3

const BlogPostMostPopularPostsContainer = ({ posts, title }) => {
  const [postMap, setPostMap] = useState([])

  useEffect(() => {
    const fetchMostViewedPosts = async () => {
      try {
        const { code } = getLanguage()
        const langCode = formatToWpLanguage(code)
        const mostViewedPostIdMap = await getMostViewedPostIds({
          count: POST_COUNT,
          langCode,
        })
        if (
          !mostViewedPostIdMap ||
          !Array.isArray(mostViewedPostIdMap) ||
          !mostViewedPostIdMap.length
        ) {
          console.error("No most viewed post IDs returned")
          return
        }
        const fetchedPosts = mostViewedPostIdMap.map(id =>
          posts.find(({ id: postId }) => postId.toString() === id),
        )
        setPostMap(fetchedPosts)
      } catch (error) {
        console.error("Error fetching most viewed posts:", error)
      }
    }

    fetchMostViewedPosts()
  }, [posts])

  return postMap.length ? (
    <BlogPostMostPopularPosts postMap={postMap} title={title} />
  ) : null
}

BlogPostMostPopularPostsContainer.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

BlogPostMostPopularPostsContainer.defaultProps = {
  posts: [],
  title: "",
}

export default BlogPostMostPopularPostsContainer

import React, { useEffect } from "react"
import { graphql, StaticQuery } from "gatsby"
import { hasSidebarForLocale, renderSidebarWidgets } from "Utils/Sidebars"
import { isBrowser } from "Utils/Helpers"
import { getLanguage, languages } from "Utils/Language"
import PropTypes from "prop-types"
import "./LocaleSelector.style.scss"
import CookieService from "Utils/CookieService"
import { useLocation } from "@reach/router"

const LocaleSelector = ({ navigateToTranslation }) => {
  const { pathname } = useLocation()

  useEffect(() => {
    const locale = CookieService.get("bf_locale")
    const didRedirect = CookieService.get("bf_did_redirect")
    if (isBrowser && !didRedirect && locale) {
      const GATSBY_FE_BF_URL = "http://localhost:8000"
      const GATSBY_FE_BM_URL = "http://localhost:8000"
      const domainBF = GATSBY_FE_BF_URL.split("://")[1]
      const domainBM = GATSBY_FE_BM_URL.split("://")[1]

      CookieService.set("bf_did_redirect", "true", {
        path: "/",
        domain: domainBF,
      })
      CookieService.set("bf_did_redirect", "true", {
        path: "/",
        domain: domainBM,
      })

      const { url } = languages[locale]

      if (!pathname.includes(url)) {
        navigateToTranslation(languages[locale])
      }
    }
  }, [navigateToTranslation, pathname])

  return (
    <StaticQuery
      query={graphql`
        query HomePageQuery {
          site {
            siteMetadata {
              description
            }
          }
        }
      `}
      render={data => {
        const localeSelector = data?.localeSelector
        const country = CookieService.get("bf_geolocation_country")
        const locale = CookieService.get("bf_locale")
        const { language } = getLanguage()

        if (
          hasSidebarForLocale(localeSelector, language) &&
          country === "CAN" &&
          !locale
        ) {
          return (
            <div className="LocaleSelector">
              {renderSidebarWidgets(localeSelector, language)}
            </div>
          )
        }

        return null
      }}
    />
  )
}

LocaleSelector.propTypes = {
  navigateToTranslation: PropTypes.func.isRequired,
}

export default LocaleSelector

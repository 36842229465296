import React, { useEffect, useState } from "react"
import Autocomplete from "react-google-autocomplete"
import { getLanguage } from "Utils/Language"

const AddressAutocomplete = ({ handleSelect }) => {
  const [countrySlug, setCountrySlug] = useState(null)

  const { code } = getLanguage()

  // useEffect to update country slug
  useEffect(() => {
    if (code === "us_en" || code === "us-es") {
      setCountrySlug("us")
    }
    if (code === "ca_en" || code === "qc-fr" || code === "qc-en") {
      setCountrySlug("ca")
    }
    return () => {}
  }, [code, countrySlug])

  return (
    <div>
      <Autocomplete
        apiKey={`${process.env.GATSBY_GOOGLE_API_KEY}`}
        options={{
          types: ["address"],
          componentRestrictions: { country: [countrySlug] }, // Limit search to US addresses
        }}
        onPlaceSelected={place => {
          if (handleSelect) {
            handleSelect(place)
          }
        }}
      />
    </div>
  )
}

export default AddressAutocomplete

import React from "react"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"

import "./BackToTop.style.scss"
const BackToTop = ({ t }) => (
  <button
    className="BackToTop"
    onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
  >
    <span className="BackToTop-Content">{t("backToTop.content")}</span>
  </button>
)

BackToTop.propTypes = {
  t: PropTypes.func.isRequired,
}

export default withTranslation()(BackToTop)

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import "./Dropdown.style.scss"
import i18next from "i18next"
import { languages } from "Utils/Language"

const Dropdown = ({
  onOptionClick = () => {},
  placeholderText = "",
  options = {},
  preselected = {},
  isChangeOnLanguageChange = false,
}) => {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false)
  const [selectedOption, setSelectedOption] = useState(preselected || {})

  useEffect(() => {
    if (isChangeOnLanguageChange)
      i18next.on("languageChanged", languageChangeEventHandler)

    return () => {
      if (isChangeOnLanguageChange)
        i18next.off("languageChanged", languageChangeEventHandler)
    }
  }, [isChangeOnLanguageChange])

  useEffect(() => {
    const setPreselect = () => {
      const prevPreselectedCode = preselected.code
      const preselectedCode = preselected.code

      if (preselectedCode && preselectedCode !== prevPreselectedCode) {
        setSelectedOption(preselectedCode)
      }
    }

    setPreselect()
  }, [preselected])

  const languageChangeEventHandler = lng => {
    setSelectedOption(languages[lng])
  }

  const handleDropdownClick = () => {
    setIsDropdownOpened(!isDropdownOpened)
  }

  const handleOptionClick = option => {
    setIsDropdownOpened(false)
    setSelectedOption(option)

    if (onOptionClick) onOptionClick(option)
  }

  const renderOptions = () => {
    return (
      <div className={`Options ${isDropdownOpened ? "isDropdownOpened" : ""}`}>
        {Object.entries(options).map(([key, option]) =>
          option.enabled ? (
            <button
              key={key}
              className="Option"
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </button>
          ) : null,
        )}
      </div>
    )
  }

  return (
    <div className="Dropdown">
      <button
        className={`SelectButton ${isDropdownOpened ? "isDropdownOpened" : ""}`}
        onClick={handleDropdownClick}
      >
        {selectedOption.label || placeholderText || "placeholder"}
        <span
          className={`PlusButton ${
            isDropdownOpened ? "MinusIcon" : "PlusIcon"
          }`}
        />
        <span
          className={`ArrowButton ${
            isDropdownOpened ? "ArrowUp" : "ArrowDown"
          }`}
        />
      </button>
      {renderOptions()}
    </div>
  )
}

Dropdown.propTypes = {
  onOptionClick: PropTypes.func,
  placeholderText: PropTypes.string,
  options: PropTypes.objectOf(PropTypes.object),
  preselected: PropTypes.objectOf(PropTypes.string),
  isChangeOnLanguageChange: PropTypes.bool,
}

export default Dropdown

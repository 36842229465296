import React, { useEffect, useState } from "react"
import ReactModal from "react-modal"
import CookieService from "Utils/CookieService"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import "./ModalEuPopup.style.scss"
import Cancel from "Assets/Cancel.svg"
import { navigate } from "gatsby"

ReactModal.setAppElement("#___gatsby")

const blockName = "ModalPopupEU"

const ModalEuPopup = ({ t, languageCode }) => {
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    const country = CookieService.get("bf_geolocation_continent")
    const isOpen = country === "EU"
    setShowModal(isOpen)
  }, [])

  const handleCloseModal = () => setShowModal(false)

  const handleRedirect = () => navigate(`https://residential.bathfitter.ie/`)

  return (
    <div>
      <ReactModal
        overlayClassName={`${blockName} `}
        isOpen={showModal}
        style={{
          content: {
            top: "15%",
            left: "25%",
            right: "initial",
            bottom: "initial",
            overflow: "hidden",
            borderRadius: "2px",
            padding: "15px",
          },
        }}
      >
        <div className="CancelButtonRow">
          <Cancel onClick={handleCloseModal} className="CancelButton" />
        </div>
        <div className="EUPopup">
          <p className="salutation">{t("EUPopUp.salutation")}</p>
          <p className="info">{t("EUPopUp.info")}</p>
          <p className="body">{t("EUPopUp.body")}</p>
          <div className="ConfirmationActions">
            <button className="Submit" tabIndex="-1" onClick={handleRedirect}>
              {t("EUPopUp.confirmBtn")}
            </button>
            <button className="Edit" tabIndex="-1" onClick={handleCloseModal}>
              {t("EUPopUp.stayHere")}
            </button>
          </div>
        </div>
      </ReactModal>
    </div>
  )
}

ModalEuPopup.propTypes = {
  languageCode: PropTypes.string,
  t: PropTypes.func.isRequired,
}

export default withTranslation()(ModalEuPopup)

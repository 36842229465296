import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { createReactFromNode } from "Utils/ReactFromNode"
import { removeHtmlTags } from "Utils/Helpers"
import {
  ElemsTransitionPropType,
  ElemsTransitionDefaultProps,
} from "Components/BlockTransition"

import LocationFinderSubmit from "./LocationFinderSubmit.component"

const LocationFinderSubmitContainer = ({
  node,
  elemsTransition = ElemsTransitionDefaultProps,
}) => {
  const [props, setProps] = useState({})

  useEffect(() => {
    const createProps = () => {
      const { children } = node

      if (!children.length) return null

      const newProps = children.reduce(
        (acc, { children, attribs, attribs: { class: className } }) => {
          if (className === "ZipCodeInput") {
            const { placeholder } = attribs
            acc.inputPlaceholder = removeHtmlTags(placeholder)
          } else {
            acc.submitButtonText = createReactFromNode(children)
          }
          return acc
        },
        {},
      )

      setProps(newProps)
    }

    createProps()
  }, [node])

  return <LocationFinderSubmit {...props} elemsTransition={elemsTransition} />
}

LocationFinderSubmitContainer.propTypes = {
  node: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  ).isRequired,
  elemsTransition: ElemsTransitionPropType,
}

export default LocationFinderSubmitContainer

import React from "react"
import PropTypes from "prop-types"
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonNext,
  ButtonBack,
  DotGroup,
} from "pure-react-carousel"
import { htmlToReact } from "Utils/HtmlParser"
import Arrow from "Assets/Arrow.svg"
import "./ComparisonBlockTable.style.scss"
import "./ComparisonBlockTableMobile.style.scss"

const ComparisonBlockTableMobile = ({
  firstColumn,
  lastColumn,
  middleColumn,
  borderColor,
}) => {
  const renderCell = ({ content }) => (
    <div className="Cell">{htmlToReact(content)}</div>
  )

  const renderColumn = cells => (
    <div className="Column">{cells.map(renderCell)}</div>
  )

  const renderSlider = () => (
    <Slider className="Slider">
      {middleColumn.map((columnData, index) => (
        <Slide key={index} className="SliderSlide">
          {renderColumn(columnData)}
        </Slide>
      ))}
    </Slider>
  )

  const renderBody = () => {
    const totalSlides = middleColumn.length

    const style = { borderColor }

    return (
      <div className="MobileBody" style={style}>
        <CarouselProvider
          totalSlides={totalSlides}
          infinite
          className="Carousel"
          lockOnWindowScroll
        >
          <div className="TableWrapper">
            <div className="FirstColumn">{firstColumn.map(renderColumn)}</div>
            <div className="MiddleColumn">{renderSlider()}</div>
            <div className="LastColumn">{lastColumn.map(renderColumn)}</div>
          </div>
          {renderCarouselControls()}
        </CarouselProvider>
      </div>
    )
  }

  const renderCarouselControls = () => (
    <div className="CarouselControls">
      <ButtonBack className="NoButtonStyle">
        <Arrow className="CarouselArrow" />
      </ButtonBack>
      <DotGroup className="DotGroup" />
      <ButtonNext className="NoButtonStyle">
        <Arrow className="CarouselArrow isRightArrow" />
      </ButtonNext>
    </div>
  )

  return <div className="ComparisonBlockTable">{renderBody()}</div>
}

ComparisonBlockTableMobile.propTypes = {
  firstColumn: PropTypes.arrayOf(PropTypes.object),
  lastColumn: PropTypes.arrayOf(PropTypes.object),
  middleColumn: PropTypes.arrayOf(PropTypes.object),
  borderColor: PropTypes.string,
}

ComparisonBlockTableMobile.defaultProps = {
  middleColumn: [],
  firstColumn: [],
  lastColumn: [],
  borderColor: "",
}

export default ComparisonBlockTableMobile

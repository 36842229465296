import React from "react"
import PropTypes from "prop-types"
import { createReactFromNode } from "Utils/ReactFromNode"
import TagsBlock from "./TagsBlock.component"

const TagsBlockContainer = ({ node }) => {
  const createData = () => {
    if (node.children.length < 2) return null

    const {
      0: { children: tagsMap },
      1: { children: imagesMap },
    } = node.children

    const tagsData = tagsMap.reduce((acc, { children, attribs: { id } }) => {
      acc[id] = {
        value: children[0].data,
        id,
      }
      return acc
    }, {})

    const imagesData = imagesMap.map(
      ({ attribs: { id, url = "", tagids }, children }) => {
        const dataObject = {
          id,
          tagIds: tagids ? tagids.split(",") : [],
          ...children.reduce(
            (
              acc,
              { name, attribs: { src, largeSrc, class: className }, children },
            ) => {
              if (name === "img") {
                acc.hash = url

                if (className && className.includes("ImageAfter")) {
                  acc.afterLargeSrc = largeSrc
                  acc.afterSrc = src
                } else {
                  acc.largeSrc = largeSrc
                  acc.src = src
                }
              } else {
                const data = createReactFromNode(children)
                if (data) {
                  if (className === "Title") acc.caption = data[0]
                  if (className === "Description") acc.description = data[0]
                }
              }
              return acc
            },
            {},
          ),
        }
        return dataObject
      },
    )

    return {
      imagesData,
      tagsData,
    }
  }

  const { imagesData, tagsData } = createData()

  return <TagsBlock imagesData={imagesData} tagsData={tagsData} />
}

TagsBlockContainer.propTypes = {
  node: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default TagsBlockContainer

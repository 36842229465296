import React from "react"
import PropTypes from "prop-types"
import { useSharedContext } from "Src/contexts/SharedContext"
import BlogLatestPosts from "./BlogLatestPosts.component"

export const POST_COUNT = 6
const POST_COUNT_IN_SINGLE_SLIDE_MOBILE = 1
const POST_COUNT_IN_SINGLE_SLIDE_DESKTOP = 3

const BlogLatestPostsContainer = ({
  posts = [],
  blockTitle = "",
  backgroundColor = "",
}) => {
  const { activePageCategory, pathName, isMobile } = useSharedContext()

  const filteredPosts = posts.filter(post => {
    if (!activePageCategory || !post?.categories) return true

    return post?.categories.some(
      ({ category_name }) =>
        category_name.toLowerCase() === activePageCategory.name.toLowerCase(),
    )
  })

  const filteredUniquePosts = []
  const seenTitles = new Set()

  filteredPosts.forEach(post => {
    if (
      !seenTitles?.has(post?.title) &&
      post?.lang === pathName?.split("/")?.[1]
    ) {
      filteredUniquePosts.push(post)
      seenTitles.add(post?.title)
    }
  })

  const postMap = filteredUniquePosts.slice(0, POST_COUNT)
  const postCountInSingleSlide = isMobile
    ? POST_COUNT_IN_SINGLE_SLIDE_MOBILE
    : POST_COUNT_IN_SINGLE_SLIDE_DESKTOP
  const totalSlides = Math.ceil(postMap.length / postCountInSingleSlide)
  const maxPostsInSlide = totalSlides * postCountInSingleSlide

  if (!postMap.length) return null

  return (
    <BlogLatestPosts
      posts={posts}
      blockTitle={blockTitle}
      postMap={postMap}
      totalSlides={POST_COUNT}
      maxPostsInSlide={maxPostsInSlide}
      isMobile={isMobile}
      backgroundColor={backgroundColor}
      visibleSlides={postCountInSingleSlide}
    />
  )
}

BlogLatestPostsContainer.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  blockTitle: PropTypes.string,
  backgroundColor: PropTypes.string,
}

export default BlogLatestPostsContainer

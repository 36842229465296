import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { getUserById } from "Utils/WpRequests"
import {
  formatToWpLanguage,
  getLanguage,
  defaultLanguageCode,
} from "Utils/Language"
import BlogPostAuthor from "./BlogPostAuthor.component"

const BlogPostAuthorContainer = ({ author, backgroundColor }) => {
  const [authorData, setAuthorData] = useState({
    name: "",
    description: "",
    imageSrc: "",
  })

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { code } = getLanguage()
        const userData = await getUserById(author)
        if (!userData) return

        const { name, avatar_urls, user_meta } = userData
        const [imageSrc] = Object.values(avatar_urls)

        const descriptionId = `description${
          code === defaultLanguageCode ? "" : `_${formatToWpLanguage(code)}`
        }`
        const [description] = user_meta[descriptionId]

        setAuthorData({
          name,
          description,
          imageSrc,
        })
      } catch (error) {
        console.error("Error fetching user data:", error)
      }
    }

    fetchUser()
  }, [author])

  const { name, description, imageSrc } = authorData

  if (!(name || description || imageSrc)) return null

  return (
    <BlogPostAuthor
      name={name}
      description={description}
      imageSrc={imageSrc}
      backgroundColor={backgroundColor}
    />
  )
}

BlogPostAuthorContainer.propTypes = {
  author: PropTypes.number.isRequired,
  backgroundColor: PropTypes.string,
}

export default BlogPostAuthorContainer

import React from "react"
import PropTypes from "prop-types"
import BlogPostCard from "./BlogPostCard.component"

const BlogPostCardContainer = ({
  post,
  showedElems,
  customRenderAfterImage,
}) => {
  const getDynamicComponentProps = () => {
    return showedElems.reduce((acc, elem) => {
      const isValPassedAsObj = typeof elem === "object"
      const id = isValPassedAsObj ? elem.id : elem

      if (id === "title") {
        acc.title = post.title || ""
      }

      if (id === "image") {
        const { featured_media } = post
        if (featured_media) {
          acc.imageSrc = featured_media.source_url || ""
          if (isValPassedAsObj) {
            acc.imageStyle = elem.style || {}
          }
        }
      }

      if (id === "category" || id === "categories") {
        acc.categories = post.categories || []
      }

      return acc
    }, {})
  }

  if (!post) return null

  const { wordpress_id: id, slug } = post

  return (
    <BlogPostCard
      id={id}
      slug={slug}
      customRenderAfterImage={customRenderAfterImage}
      {...getDynamicComponentProps()}
    />
  )
}

BlogPostCardContainer.propTypes = {
  post: PropTypes.shape({
    title: PropTypes.string,
    featured_media: PropTypes.shape({
      source_url: PropTypes.string,
    }),
    categories: PropTypes.array,
    wordpress_id: PropTypes.number,
    slug: PropTypes.string,
  }).isRequired,
  showedElems: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ).isRequired,
  customRenderAfterImage: PropTypes.func,
}

BlogPostCardContainer.defaultProps = {
  customRenderAfterImage: () => {},
}

export default BlogPostCardContainer

import { useStaticQuery, graphql } from "gatsby"

export const useFooter = () => {
  const data = useStaticQuery(graphql`
    query FooterCopyrightQuery {
      copyright: allWidgets(
        filter: { parent_sidebar: { eq: "bathfitter-footer-copyright" } }
      ) {
        nodes {
          rendered
          language
        }
      }
      menu: allWidgets(
        filter: { parent_sidebar: { eq: "bathfitter-footer-menu" } }
      ) {
        nodes {
          rendered
          language
        }
      }
      social: allWidgets(
        filter: { parent_sidebar: { eq: "bathfitter-footer-social-links" } }
      ) {
        nodes {
          rendered
          language
        }
      }
      description: allWidgets(
        filter: { parent_sidebar: { eq: "bathfitter-footer-description" } }
      ) {
        nodes {
          rendered
          language
        }
      }
      contact: allWidgets(
        filter: { parent_sidebar: { eq: "bathfitter-footer-contact" } }
      ) {
        nodes {
          rendered
          language
        }
      }
      contactCommercial: allWidgets(
        filter: {
          parent_sidebar: { eq: "bathfitter-footer-contact-commercial-sales" }
        }
      ) {
        nodes {
          rendered
          language
        }
      }
      footerMenuAdditionalColumn: allWidgets(
        filter: {
          parent_sidebar: { eq: "bathfitter-footer-menu-additional-column" }
        }
      ) {
        nodes {
          rendered
          language
        }
      }
      logo: allWidgets(filter: { parent_sidebar: { eq: "bathfitter-logo" } }) {
        nodes {
          rendered
          parent_sidebar
          id
          language
        }
      }
      footerMenu: allMenuItems(filter: { slug: { regex: "/^footer-menu/" } }) {
        nodes {
          slug
          items {
            title
            object_slug
            object_id
            type
            url
            children {
              title
              url
              type
              object_slug
              object_id
            }
          }
        }
      }

      footerCommercialMenu: allMenuItems(
        filter: { slug: { regex: "/^footer-commercial-menu/" } }
      ) {
        nodes {
          slug
          items {
            title
            object_slug
            object_id
            type
            url
            children {
              title
              url
              type
              object_slug
              object_id
            }
          }
        }
      }

      copyrightMenu: allMenuItems(
        filter: { slug: { regex: "/^footer-copyright-menu/" } }
      ) {
        nodes {
          slug
          items {
            title
            object_slug
            object_id
            type
            url
          }
        }
      }
    }
  `)

  return {
    data,
  }
}

import React, { useState } from "react"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import { useSharedContext } from "Src/contexts/SharedContext"
import ShareWrapper from "Components/ShareWrapper"
import TwoPassRendering from "Components/TwoPassRendering"
import Social from "Assets/Social.svg"
import { getLogoSrc, isBrowser } from "Utils/Helpers"
import "./ClientStoriesTilesBlock.style.scss"
import { getLanguage } from "Utils/Language"
import ShareButton from "Components/ShareButton"

const SHARE_ID = "#st"

const ClientStoriesTilesBlock = ({ listMap, t, pageContext }) => {
  const { isMobile } = useSharedContext()
  const [activeTileIndex, setActiveTileIndex] = useState(null)
  const [tilesInRowDesktop, setTilesInRowDesktop] = useState(3)
  const [tilesInRowMobile, setTilesInRowMobile] = useState(4)

  const getValueByClassName = (elem, elemClassName = "") => {
    if (!elemClassName) return null
    const matchingChild = elem.children.find(child => {
      const { props } = child
      const { className } = props || {}
      return className ? className.split(" ").includes(elemClassName) : false
    })
    if (!matchingChild) return null
    return matchingChild.props.children
  }

  const getRowCount = () => {
    const mobileRowCount = 2
    const desktopRowCount = 3
    return isMobile ? mobileRowCount : desktopRowCount
  }

  const handleShowMore = () => {
    const extraRows = 3
    isMobile
      ? setTilesInRowMobile(tilesInRowMobile + extraRows)
      : setTilesInRowDesktop(tilesInRowDesktop + extraRows)
  }

  const checkIfFeatured = (array, index) => {
    const { className } = array[index].props
    const featured = "Featured "
    if (className && className.includes(featured)) {
      return "SocialIcon Featured"
    }
    return "SocialIcon"
  }

  const handlePopupOpen = childIndex => {
    setActiveTileIndex(childIndex)
  }

  const handlePopupClose = () => {
    setActiveTileIndex(null)
  }

  const renderPopup = index => {
    const activeTile = listMap[activeTileIndex]
    const { props: activeTileProps = {} } = activeTile || {}
    const { url: hash = "", media = "" } = activeTileProps

    if (!activeTile) return null

    const { code } = getLanguage()
    const shareImage = media || getLogoSrc(code)
    const [title] = getValueByClassName(activeTileProps, "Title")
    const [description] = getValueByClassName(activeTileProps, "Content")

    const documentTitle = isBrowser ? document.title : ""
    const shareTitle = title && title.trim() ? title : documentTitle

    if (activeTileIndex === index) {
      return (
        // eslint-disable-next-line
        <div className="ModalPopup" onClick={() => handlePopupClose()}>
          <div className="ModalLayer" />
          {renderShareButtons(shareImage, index, shareTitle, description, hash)}
        </div>
      )
    }
    return null
  }

  const renderShareButtons = (src, id, caption, description, hash) => {
    if (!isBrowser) {
      return null
    }

    const {
      pageContext: { meta = {} },
    } = pageContext
    const { hash: pageHash = "" } = meta

    const {
      location: { origin, pathname },
    } = window
    const url = `${origin}${
      pageHash ? pathname.replace(`${pageHash}/`, "") : pathname
    }${hash ? `${hash}/` : ""}${SHARE_ID}${id}`
    const documentTitle = isBrowser ? document.title : ""
    const shareCaption = caption && caption.trim() ? caption : documentTitle

    return (
      <div className="ShareButtonsWrapper">
        <p className="ShareTitle">{t("likePinterestBlock.share")}</p>
        <div className="ShareButtons">
          <ShareButton type="facebook" url={url} />
          <ShareButton type="twitter" url={url} twitterTitle={shareCaption} />
          <ShareButton
            type="pinterest"
            url={url}
            media={src}
            description={description}
          />
          <ShareButton
            type="email"
            url={url}
            media={src}
            subject={shareCaption}
            body={description}
          />
        </div>
      </div>
    )
  }

  const renderTilesBlock = (rowIndex, columnIndex) => {
    const childIndex = rowIndex + getRowCount() * columnIndex

    if (listMap.length < childIndex + 1) return null

    const activeTile = listMap[childIndex]
    const { props: activeTileProps = {} } = activeTile || {}
    const [title] = getValueByClassName(activeTileProps, "Title")
    const [description] = getValueByClassName(activeTileProps, "Content")
    const { code } = getLanguage()
    const shareImage = getLogoSrc(code)

    return (
      <div
        key={childIndex}
        className="TileBlockWrapper"
        id={`${SHARE_ID.replace("#", "")}${childIndex}`}
      >
        <ShareWrapper
          isActive={activeTileIndex === childIndex}
          itemId={childIndex}
          shareId={SHARE_ID}
          metaTitle={title}
          metaImageSrc={shareImage}
          metaDescription={description}
        >
          {listMap[childIndex]}
          <div className="RowSocial">
            <Social
              className={checkIfFeatured(listMap, childIndex)}
              onClick={() => handlePopupOpen(childIndex)}
            />
          </div>
          {renderPopup(childIndex)}
        </ShareWrapper>
      </div>
    )
  }

  const renderRow = rowIndex => {
    const listLength = listMap?.length || 0
    const rowCount = getRowCount() || 1
    const tileCount = Math.ceil(listLength / rowCount)

    if (isNaN(tileCount) || tileCount <= 0) {
      return console.error("Invalid tileCount:", tileCount)
    }

    const maxTilesInRow = showTilesCountInARow()

    if (isNaN(maxTilesInRow) || maxTilesInRow <= 0) {
      return console.error("Invalid maxTilesInRow:", maxTilesInRow)
    }

    const tileSliceEnd = Math.min(maxTilesInRow, tileCount)

    const tileMap = [...new Array(tileCount)].slice(0, tileSliceEnd)

    return (
      <div key={rowIndex} className="RowWrapper">
        {tileMap.map((_, columnIndex) =>
          renderTilesBlock(rowIndex, columnIndex),
        )}
      </div>
    )
  }

  const showTilesCountInARow = () =>
    isMobile ? tilesInRowMobile : tilesInRowDesktop

  return (
    <TwoPassRendering>
      <div className="ClientStoriesTilesBlockWrapper">
        <div className="ContentWrapper">
          {[...new Array(getRowCount())].map((_, index) => renderRow(index))}
        </div>
        <div className="Row">
          <button className="LoadMore" onClick={handleShowMore}>
            {t("clientStories.loadMoreStories")}
          </button>
        </div>
      </div>
    </TwoPassRendering>
  )
}

ClientStoriesTilesBlock.propTypes = {
  listMap: PropTypes.arrayOf(PropTypes.object).isRequired,
  pageContext: PropTypes.objectOf(PropTypes.object),
  t: PropTypes.func.isRequired,
}

ClientStoriesTilesBlock.defaultProps = {
  pageContext: {},
}

export default withTranslation()(ClientStoriesTilesBlock)

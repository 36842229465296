import React, { useState, useEffect } from "react"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import { validateFieldLength } from "Utils/NinjaFormValidation"
import PhoneNumberInput from "Components/InputMasking"
import { getLanguage } from "Utils/Language"
import { gtmFormEventPush } from "Utils/Gtm"
import htmlParser from "Utils/HtmlParser"
import { navigate } from "gatsby"
import CookieService from "Utils/CookieService"
import "./NinjaForm.style.scss"
import { useNinjaContextProvider } from "Src/contexts/NinjaFormContext"
import { Rings } from "react-loader-spinner"
import AddressAutocomplete from "../AddressAutocomplete/AddressAutocomplete.component"
import "@fortawesome/fontawesome-free/css/all.min.css"
import UsaFlag from "../../images/usa.png"
import CaFlag from "../../images/ca.png"

const NinjaForm = ({ nf_assets, t, submitForm = null, data = null }) => {
  const { formSubmission, setNfAssets, setUpdatedNfAssets } =
    useNinjaContextProvider()
  const { fields, formID, success_message } = nf_assets

  const initialFieldState = fields.map(field => ({
    ...field,
    value: field.type === "submit" ? field.label : "",
  }))

  const handleFocusChange = () => {
    setIsOpen(false)
  }

  const [errors, setErrors] = useState(fields.map(() => ({ error: "" })))
  const [fieldState, setFieldState] = useState(initialFieldState)
  const [campaignId, setCampaignId] = useState("")
  const [utmSource, setUtmSource] = useState("")
  const [utmMedium, setUtmMedium] = useState("")
  const [utmCampaign, setUtmCampaign] = useState("")

  const [fbclid, setFbclid] = useState("")
  const [gclid, setGclid] = useState("")
  const [gbraid, setGbraid] = useState("")
  const [wbraid, setWbraid] = useState("")
  const [dclid, setDclid] = useState("")
  const [ttclid, setTtclid] = useState("")
  const [lifatid, setLifatid] = useState("")
  const [epik, setEpik] = useState("")
  const [sccid, setSccid] = useState("")
  const [msclkid, setMsclkid] = useState("")

  const [fbc, setFbc] = useState("")
  const [fbp, setFbp] = useState("")
  const [gauserid, setGauserid] = useState("")
  const [gaclientid, setGaclientid] = useState("")

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  const [scrollToDiv, setScrollToDiv] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [countryValue, setCountryValue] = useState("")

  useEffect(() => {
    const campaignData = () => {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)

      const queryParams = [
        "cid",
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "fbclid",
        "gclid",
        "gbraid",
        "wbraid",
        "dclid",
        "ttclid",
        "li_fat_id",
        "epik",
        "sccid",
        "msclkid",
      ]

      queryParams.forEach(param => {
        switch (param) {
          case "cid":
            setCampaignId(urlParams.get(param))
            break
          case "utm_source":
            setUtmSource(urlParams.get(param))
            break
          case "utm_medium":
            setUtmMedium(urlParams.get(param))
            break
          case "utm_campaign":
            setUtmCampaign(urlParams.get(param))
            break
          case "fbclid":
            setFbclid(urlParams.get(param))
            break
          case "gclid":
            setGclid(urlParams.get(param))
            break
          case "gbraid":
            setGbraid(urlParams.get(param))
            break
          case "wbraid":
            setWbraid(urlParams.get(param))
            break
          case "dclid":
            setDclid(urlParams.get(param))
            break
          case "ttclid":
            setTtclid(urlParams.get(param))
            break
          case "li_fat_id":
            setLifatid(urlParams.get(param))
            break
          case "epik":
            setEpik(urlParams.get(param))
            break
          case "sccid":
            setSccid(urlParams.get(param))
            break
          case "msclkid":
            setMsclkid(urlParams.get(param))
            break
          default:
            break
        }
      })

      setFbc(CookieService.get("_fbc"))
      setFbp(CookieService.get("_fbp"))
      setGauserid(CookieService.get("_gid"))
      setGaclientid(CookieService.get("_ga"))
    }

    campaignData()
  }, [])

  useEffect(() => {
    if (data) {
      updateFieldState()
    }
    return () => {}
  }, [data])

  useEffect(() => {
    setNfAssets(nf_assets)
    setUpdatedNfAssets(fieldState)
  }, [nf_assets, fieldState])

  useEffect(() => {
    if (scrollToDiv) {
      document
        .getElementById("frm")
        .scrollIntoView({ top: -1000, behavior: "smooth" })
      setScrollToDiv(false)
    }
  }, [scrollToDiv])

  const getFieldType = (fieldType, type) =>
    (fieldType =
      fieldType === "phone" || fieldType === "email" || fieldType === "zip"
        ? fieldType
        : type)

  const handleInputChange = (index, event, fieldType) => {
    const { value, type } = event.target
    fieldType = getFieldType(fieldType, type)

    const updatedFieldState = [...fieldState]

    if (fieldType === "listcheckbox" || fieldType === "checkbox") {
      let currentValue = updatedFieldState[index].value || ""
      const checkboxValue = value.toString()

      if (event.target.checked) {
        currentValue += currentValue ? `,${checkboxValue}` : checkboxValue
      } else {
        const values = currentValue.split(",")
        const filteredValues = values.filter(v => v !== checkboxValue)
        currentValue = filteredValues.join(",")
      }

      updatedFieldState[index].value = currentValue
    } else {
      updatedFieldState[index].value = value
    }

    setFieldState(updatedFieldState)
  }

  const updateFieldState = () => {
    const updatedFieldState = [...fieldState]
    updatedFieldState.forEach((field, index) => {
      if (field.label === "id") {
        updatedFieldState[index].value = data.id
      }
      if (field.label === "timeslot") {
        updatedFieldState[index].value = data.timeslot
      }
      if (field.label === "service") {
        updatedFieldState[index].value = data.service
      }

      if (field.type === "zip") {
        updatedFieldState[index].value = data.zip
      }
    })
    return updatedFieldState
  }

  const errorValidation = (index, value, placeholder, type) => {
    if (type === "phone") {
      handleFocusChange()
      const ignoreValue = "(___) ___-____"

      if (value === ignoreValue) return
    }

    if (type !== "listcountry") {
      let error = ""
      const { url } = getLanguage()
      error = validateFieldLength(value, placeholder, url, type, t)
      const errorsState = [...errors]
      errorsState[index].error = error
      setErrors(errorsState)
    }
  }

  const handleSubmit = async (formID, event) => {
    event.preventDefault()

    if (isSubmitting) return

    setIsSubmitting(true)

    const settings = ["label", "type", "default", "required"]
    const extra = {
      cid: campaignId,
      utmSource,
      utmMedium,
      utmCampaign,
      fbclid,
      gclid,
      gbraid,
      wbraid,
      dclid,
      ttclid,
      lifatid,
      epik,
      sccid,
      msclkid,
      fbc,
      fbp,
      gauserid,
      gaclientid,
    }
    let errorStatus = false

    fields.forEach((field, index) => {
      let fieldLabel = field.placeholder || field.label
      errorValidation(index, fieldState[index].value, fieldLabel, field.type)
      if (errors[index].error) {
        errorStatus = true
      }
    })

    if (!errorStatus) {
      if (submitForm) {
        setIsSubmitting(false)

        submitForm()
      } else {
        try {
          const data = await formSubmission(
            formID,
            fieldState,
            settings,
            extra,
            false,
          )
          if (data.status === 200) {
            setFieldState(initialFieldState)
            window.gtmFormEventPush = gtmFormEventPush(
              "consultationFormSubmit",
              [],
            )
            setScrollToDiv(true)
            setSuccessMessage(
              "Form has been successfully submitted. Thank you.",
            )
            if (data.body) {
              bookConsultationRedirect(data, data.body.data.guid)
            }
          } else {
            alert(data.message)
          }
        } catch (error) {
          console.error("Error submitting form:", error)
        } finally {
          setIsSubmitting(false)
        }
      }
    } else {
      setIsSubmitting(false)
    }
  }

  const bookConsultationRedirect = (response, LeadID) => {
    const {
      data: { first_name: firstName, last_name: lastName, zip: zipCode },
      type,
    } = response

    if (type !== "error") {
      const leadData = {
        LeadID,
        firstName,
        lastName,
        zipCode,
      }

      const date = new Date()
      const expireTime = 60 * 45 * 1000
      date.setTime(date.getTime() + expireTime)

      CookieService.set("bf_lead_data", leadData, {
        path: "/",
        expires: date,
      })

      const { url } = getLanguage()
      if (url === "/qc-fr") {
        navigate(`${url}/merci`)
      } else if (url === "/us-es") {
        navigate(`${url}/thank-you`)
      } else {
        navigate(`${url}/thankyou`)
      }
    }
  }

  const handleCountry = (optionLabel, index, fieldType) => {
    handleInputChange(
      index,
      { target: { value: `${optionLabel === "CA (+1)" ? "CA" : "US"}` } },
      fieldType,
    )
    setCountryValue(optionLabel)
    setIsOpen(false)
  }
  const handleSelect = place => {
    const { address_components } = place
    const addressData = {}
    address_components.map(address => {
      const { long_name, short_name, types } = address

      if (types.includes("street_number")) {
        addressData["street_number"] = short_name
      }
      if (types.includes("route")) {
        addressData["route"] = long_name
      }
      if (types.includes("locality")) {
        addressData["locality"] = long_name
      }
      if (types.includes("administrative_area_level_1")) {
        addressData["state"] = short_name
      }
      if (types.includes("country")) {
        addressData["country"] = short_name
      }
    })
    updateFormData(addressData)
  }

  const updateFormData = addressData => {
    const updatedFieldState = [...fieldState]

    const addressArr = [
      "Address (Number and Street)",
      "Rechercher Adresse",
      "Calle",
    ]
    const stateArr = ["State", "Province", "Estado"]
    updatedFieldState.forEach((field, index) => {
      if (field.type === "city" || field.type === "City") {
        updatedFieldState[index].value = addressData.locality
      }
      if (stateArr.includes(field.label)) {
        let state = ""
        if (addressData.country === "US" || addressData.country === "CA") {
          state = addressData.country + "-" + addressData.state
        }
        updatedFieldState[index].value = state
      }
      if (addressArr.includes(field.label)) {
        let address = addressData.street_number
          ? addressData.street_number + " "
          : ""
        address += addressData.route ? addressData.route : ""
        updatedFieldState[index].value = address
      }
    })
    setFieldState(updatedFieldState)
  }

  useEffect(() => {
    const field1Elements = document.querySelectorAll(".custom-dropdown")
    const field2Elements = document.querySelectorAll(".PhoneInputCountryIcon")
    const parentDivElements = [...field2Elements].map(
      field2 => field2.parentElement,
    )

    if (
      field1Elements.length > 0 &&
      field2Elements.length > 0 &&
      parentDivElements.length > 0
    ) {
      field1Elements.forEach((field1, index) => {
        const parent = field1.parentNode
        parent.style.display = "flex"
        parent.classList.add("country-phone")
        field1.style.width = "30%"
        // field1.style.margin = '0px';

        if (index < parentDivElements.length) {
          const parentDiv = parentDivElements[index]
          parentDiv.style.width = "100%"
          parent.appendChild(parentDiv)
        }
      })
      const countryfieldIndex = fields?.findIndex(
        item => item?.type === "listcountry",
      )
      if (countryfieldIndex) {
        var languge = getLanguage()
        var finalLanguage = languge.code
        var countryValues =
          finalLanguage === "ca_en" ||
          finalLanguage === "qc_en" ||
          finalLanguage === "qc_fr"
            ? "CA"
            : "US"
        if (countryValues === "CA") {
          setCountryValue("CA (+1)")
        } else {
          setCountryValue("US (+1)")
        }
        handleInputChange(
          countryfieldIndex,
          { target: { value: countryValues } },
          "listcountry",
        )
      }
    }
  }, [fields])

  return (
    <div id="frm">
      <div style={{ height: "auto" }}></div>
      <form
        onSubmit={e => handleSubmit(formID, e)}
        className="middle-form-content"
      >
        {successMessage && (
          <div
            className="success-message"
            dangerouslySetInnerHTML={{ __html: success_message }}
          ></div>
        )}
        {fields.map((field, index) => (
          <div key={index}>
            {field.type === "phone" ? (
              <PhoneNumberInput
                data-name={field.type}
                value={fieldState[index].value}
                onChange={e => handleInputChange(index, e, field.type)}
                onBlur={e =>
                  errorValidation(
                    index,
                    e.target.value,
                    e.target.placeholder,
                    getFieldType(field.type, e.target.type),
                  )
                }
                placeholder={field.placeholder}
                type={field.type}
                onFocus={() => handleFocusChange()}
              />
            ) : field.type === "html" ? (
              <span>{htmlParser(fieldState[index].default)}</span>
            ) : field.type === "listselect" ? (
              <select
                data-name={field.type}
                id={`dropdown-${index}`}
                name={field.label}
                value={fieldState[index].value}
                onChange={e => handleInputChange(index, e, field.type)}
                onBlur={e =>
                  errorValidation(
                    index,
                    e.target.value,
                    e.target.placeholder,
                    getFieldType(field.type, e.target.type),
                  )
                }
              >
                <option value="">{field.label}</option>
                {field.options.map(option => (
                  <option key={option.value} value={option.calc}>
                    {option.label}
                  </option>
                ))}
              </select>
            ) : field.type === "listcountry" ? (
              <div className="custom-dropdown">
                <div
                  className="selected-option"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <div className="country-option">
                    <img
                      height="15px"
                      src={`${countryValue === "CA (+1)" ? CaFlag : UsaFlag}`}
                    />
                    <span className="country-value">{"+1"}</span>
                    <span
                      className={`arrow-icon ${isOpen ? "open" : "closed"}`}
                    >
                      <i
                        className={`fas ${isOpen ? "fa-chevron-up" : "fa-chevron-down"}`}
                      />
                    </span>
                  </div>
                </div>
                {isOpen && (
                  <div className="dropdown-options">
                    {field.options.map(option => (
                      <div
                        key={option.value}
                        className="dropdown-option"
                        onClick={() =>
                          handleCountry(option.label, index, field.type)
                        }
                      >
                        <div className="flagCountry">
                          <img
                            className="option-flag"
                            height="15px"
                            src={`${option.label === "CA (+1)" ? CaFlag : UsaFlag}`}
                          />
                          {/* {option.label} */}
                          {`${option.label === "CA (+1)" ? "Canada +1" : "United States +1"}`}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ) : field.type === "listcheckbox" ? (
              <div className="checkbox-buttons">
                {field.options.map((option, optionIndex) => (
                  <label
                    key={option.value}
                    className={
                      fieldState[index].value.includes(option.value)
                        ? "active"
                        : ""
                    }
                  >
                    <input
                      data-name={field.type}
                      type="checkbox"
                      value={option.value}
                      checked={fieldState[index].value.includes(option.value)}
                      onChange={e => handleInputChange(index, e, field.type)}
                      onFocus={() => handleFocusChange()}
                      onBlur={e =>
                        errorValidation(
                          index,
                          e.target.value,
                          e.target.placeholder,
                          getFieldType(field.type, e.target.type),
                        )
                      }
                    />
                    {option.label}
                  </label>
                ))}
              </div>
            ) : field.type === "textarea" ? (
              <textarea
                data-name={field.type}
                placeholder={field.placeholder}
                rows="4"
                cols="50"
                value={fieldState[index].value}
                onChange={e => handleInputChange(index, e, field.type)}
                onFocus={() => handleFocusChange()}
                onBlur={e =>
                  errorValidation(
                    index,
                    e.target.value,
                    e.target.placeholder,
                    getFieldType(field.type, e.target.type),
                  )
                }
              ></textarea>
            ) : field.type === "hidden" ? (
              <input
                data-name={field.label}
                type={field.type === "hidden" ? "hidden" : "text"}
                value={fieldState[index].value}
                style={{ display: field.type === "hidden" ? "none" : "block" }}
              />
            ) : field.type === "submit" ? (
              <input
                data-name={field.type}
                type="submit"
                value={field.label}
                disabled={isSubmitting}
                onFocus={() => handleFocusChange()}
              />
            ) : field.type === "address" ? (
              <AddressAutocomplete handleSelect={handleSelect} />
            ) : (
              <input
                data-name={field.type}
                type={field.type === "address" ? field.type : "text"}
                value={fieldState[index].value}
                onChange={e => handleInputChange(index, e, field.type)}
                onBlur={e =>
                  errorValidation(
                    index,
                    e.target.value,
                    e.target.placeholder,
                    getFieldType(field.type, e.target.type),
                  )
                }
                placeholder={field.placeholder}
                onFocus={() => handleFocusChange()}
                style={{ display: field.type === "hidden" ? "none" : "block" }}
              />
            )}
            {errors[index].error && (
              <span style={{ color: "red" }}>{errors[index].error}</span>
            )}
          </div>
        ))}
        {isSubmitting && (
          <div className="loader">
            <div className="loader-overlay">
              <Rings
                visible={true}
                height="100px"
                width="100px"
                color="#007377"
                ariaLabel="rings-loading"
                wrapperStyle={{}}
                wrapperClass=""
                className="loader"
                style={{ color: "#007377" }}
              />
            </div>
          </div>
        )}
      </form>
    </div>
  )
}

NinjaForm.propTypes = {
  nf_assets: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
}

export default withTranslation()(NinjaForm)
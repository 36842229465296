import React, { isValidElement } from "react"
import PropTypes from "prop-types"
import TransitionOnView from "./TransitionOnView.component"
import "./BlockTransition.style.scss"

const renderElem = (children, elemsTransition) => {
  return React.Children.map(children, childNode => {
    if (!isValidElement(childNode)) return childNode

    const {
      props: { class: classProps, className: classNameProps, node, children },
      type,
    } = childNode

    const className = classNameProps || classProps || ""
    const classNameMapped = className.toLowerCase().split(" ")
    const elem = elemsTransition.find(({ className: elemClassName = "" }) =>
      classNameMapped.includes(elemClassName.toLowerCase()),
    )

    const childProps =
      node || typeof type !== "string" || type.name === "LinkContainer"
        ? {}
        : { elemsTransition }

    if (elem && type.name !== "TransitionOnView") {
      return (
        <TransitionOnView {...elem}>
          {React.cloneElement(
            childNode,
            childProps,
            renderElem(children, elemsTransition),
          )}
        </TransitionOnView>
      )
    }

    if (elem) return <TransitionOnView {...elem}>{childNode}</TransitionOnView>

    return React.cloneElement(
      childNode,
      [],
      renderElem(children, elemsTransition),
    )
  })
}

const BlockTransition = ({ blockTransition, children, elemsTransition }) => {
  const renderElemsTransition = () => {
    return elemsTransition.length
      ? renderElem(children, elemsTransition)
      : children
  }

  return (
    <TransitionOnView {...blockTransition}>
      {renderElemsTransition()}
    </TransitionOnView>
  )
}

BlockTransition.propTypes = {
  blockTransition: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  ),
  children: PropTypes.node.isRequired,
  elemsTransition: PropTypes.arrayOf(PropTypes.object),
}

BlockTransition.defaultProps = {
  blockTransition: {},
  elemsTransition: [],
}

export default BlockTransition

import React, { useState } from "react"
import PropTypes from "prop-types"
import ReactPlayer from "react-player/lazy"
import { htmlToReact } from "Utils/HtmlParser"
import BlockTransition, {
  ElemsTransitionPropType,
} from "Components/BlockTransition"

import "./Video.style.scss"
import ProgressiveImage from "Components/ProgressiveImg"
import { getPreviewImageSrc } from "Utils/Helpers"

const Video = props => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [isPlayed] = useState(true)

  const handleOnPlay = () => {
    setIsPlaying(true)
  }

  const handleOnPause = () => {
    setIsPlaying(false)
  }

  const renderTag = ([key, value]) => {
    const className = key.charAt(0).toUpperCase() + key.slice(1)

    if (!value) {
      return null
    }

    if (key === "button") {
      return (
        <div className="Button Button-Secondary" key={key}>
          {htmlToReact(value)}
        </div>
      )
    }

    return (
      <p className={className} key={key}>
        {htmlToReact(value)}
      </p>
    )
  }

  const renderContent = () => {
    const {
      content,
      videoIsAutoPlay,
      videoIsContentVisibleOnPlay,
      contentAlignment,
      videoStyle,
    } = props

    if (
      (isPlaying || (!isPlayed && videoIsAutoPlay)) &&
      !videoIsContentVisibleOnPlay
    ) {
      return null
    }

    const style = {
      textAlign: contentAlignment,
    }
    const isWithCustomAligment =
      contentAlignment !== "left" ? "isWithCustomAligment" : ""
    const isBlogStyle = videoStyle === "blog" ? "isBlogStyle" : ""

    return (
      <div className="ContentWrapper">
        <div
          className={`Content ${isWithCustomAligment} ${isBlogStyle}`}
          style={style}
        >
          {Object.entries(content).map(renderTag)}
        </div>
      </div>
    )
  }

  const renderPlayButton = () => {
    const { videoPlayButtonText, videoPlayButtonId, videoIsAutoPlay } = props

    if (
      isPlaying ||
      (!isPlayed && videoIsAutoPlay) ||
      videoPlayButtonId === "none"
    )
      return null

    const isWithText = videoPlayButtonId === "withText" ? "isWithText" : ""

    return (
      <span className={`PlayButton ${isWithText}`}>
        {isWithText && videoPlayButtonText}
      </span>
    )
  }

  const renderPosterImg = () => {
    const { posterUrl } = props

    if (!posterUrl || isPlayed) return null

    return (
      <ProgressiveImage
        src={getPreviewImageSrc(posterUrl)}
        largeSrc={posterUrl}
        className="PosterImage"
        alt="Poster"
      />
    )
  }

  // const renderOverlay = () => {
  //   const { videoOverlayIsEnabled, videoOverlayColor: { hex, opacity } = {} } =
  //     props

  //   if (!videoOverlayIsEnabled) {
  //     return null
  //   }

  //   const style = { backgroundColor: hex, opacity }

  //   return <div className="Overlay" style={style} />
  // }

  const {
    videoUrl,
    videoHeight,
    videoIsMute,
    videoIsLoop,
    videoWidthId,
    elemsTransition,
  } = props

  const style = {}
  const isFullScreenWidth =
    videoWidthId === "fullWidth" ? "isFullScreenWidth" : ""

  if (videoHeight && videoHeight !== "auto") {
    style.height = videoHeight
  }

  return (
    <BlockTransition elemsTransition={elemsTransition}>
      <div className={`VideoBlock ${isFullScreenWidth}`}>
        <div
          className={`VideoBlockContent ${
            videoHeight === "auto" ? "centerVertically" : ""
          }`}
        >
          <div className="VideoContainer">
            <ReactPlayer
              className="Video"
              playing={isPlaying}
              onPlay={handleOnPlay}
              onPause={handleOnPause}
              url={videoUrl}
              width="100%"
              height={videoHeight === "auto" ? videoHeight : ""}
              loop={videoIsLoop}
              muted={videoIsMute}
              controls={true}
            />
          </div>
          {renderPosterImg()}
          {renderContent()}
          {renderPlayButton()}
          {!isPlaying ? (
            <button
              className="VideoBlockButton"
              onClick={handleOnPlay}
              aria-label="Play video"
            />
          ) : null}
        </div>
      </div>
    </BlockTransition>
  )
}

Video.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  posterUrl: PropTypes.string.isRequired,
  videoPlayButtonId: PropTypes.string.isRequired,
  videoPlayButtonText: PropTypes.string.isRequired,
  videoWidthId: PropTypes.string.isRequired,
  videoHeight: PropTypes.string.isRequired,
  videoIsAutoPlay: PropTypes.bool.isRequired,
  videoIsLoop: PropTypes.bool.isRequired,
  videoIsMute: PropTypes.bool.isRequired,
  videoOverlayIsEnabled: PropTypes.bool.isRequired,
  videoOverlayColor: PropTypes.shape({
    hex: PropTypes.string.isRequired,
    opacity: PropTypes.number.isRequired,
    rgb: PropTypes.object.isRequired,
  }).isRequired,
  videoIsContentVisibleOnPlay: PropTypes.bool.isRequired,
  contentAlignment: PropTypes.string.isRequired,
  content: PropTypes.shape({
    microcopy: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    subHeading: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
  }).isRequired,
  videoStyle: PropTypes.string.isRequired,
  elemsTransition: ElemsTransitionPropType.isRequired,
}

export default Video

import React from "react"
import PropTypes from "prop-types"
import { useSharedContext } from "Src/contexts/SharedContext"
import CategoriesSelector from "./CategoriesSelector.component"

const CategoriesSelectorContainer = ({ posts = [], defaultButtonTitle }) => {
  const { activePageCategory } = useSharedContext()

  const categoriesMap = posts.reduce((acc, { categories }) => {
    categories.forEach(({ category_name, id }) => {
      if (!acc.some(category => category.category_name === category_name)) {
        acc.push({ category_name, id })
      }
    })
    return acc
  }, [])

  return (
    <CategoriesSelector
      categoriesMap={categoriesMap}
      activePageCategory={activePageCategory}
      defaultButtonTitle={defaultButtonTitle}
    />
  )
}

CategoriesSelectorContainer.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  defaultButtonTitle: PropTypes.string.isRequired,
}

export default CategoriesSelectorContainer

import React from "react"
import PropTypes from "prop-types"
import ShareButtonGroup from "Components/ShareButtonGroup"
import { htmlToReact } from "Utils/HtmlParser"

import "./BlogPostShare.style.scss"

const BlogPostShare = ({
  title,
  metaTitle,
  metaDescription,
  metaFeaturedMediaSrc,
  ...props
}) => {
  return (
    <div className="BlogPostShare">
      <div className="Content">
        <p className="ContentTitle">{htmlToReact(title)}</p>
      </div>
      <ShareButtonGroup
        title={metaTitle}
        description={metaDescription}
        featuredMediaSrc={metaFeaturedMediaSrc}
        {...props}
      />
    </div>
  )
}

BlogPostShare.propTypes = {
  title: PropTypes.string,
  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
  metaFeaturedMediaSrc: PropTypes.string,
}

BlogPostShare.defaultProps = {
  title: "",
  metaTitle: "",
  metaDescription: "",
  metaFeaturedMediaSrc: "",
}

export default BlogPostShare

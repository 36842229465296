import React from "react"
import PropTypes from "prop-types"
import ShareButton from "Components/ShareButton"
import { isBrowser } from "Utils/Helpers"
import "./ShareButtonGroup.style.scss"

const ShareButtonGroup = ({
  url: propsUrl,
  title,
  description,
  featuredMediaSrc,
}) => {
  const url = propsUrl || (isBrowser ? window.location.href : "")

  return (
    <div className="ShareButtonGroup">
      <ShareButton type="facebook" url={url} />
      <ShareButton type="twitter" url={url} twitterTitle={title} />
      <ShareButton
        type="pinterest"
        url={url}
        media={featuredMediaSrc}
        description={description}
      />
      <ShareButton
        type="email"
        url={url}
        subject={title}
        body={description}
        media={featuredMediaSrc}
      />
    </div>
  )
}

ShareButtonGroup.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  featuredMediaSrc: PropTypes.string.isRequired,
}

ShareButtonGroup.defaultProps = {
  url: "",
}

export default ShareButtonGroup

import React, { useEffect, createRef } from "react"
import PropTypes from "prop-types"
import Link from "Components/Link"
import LocationFinderIcon from "Assets/Point.svg"
import LocationFinderArrow from "Assets/Arrow.svg"
import { getLanguage } from "Utils/Language"
import { isBrowser } from "Utils/Helpers"
import { withTrans } from "../../i18n/withTrans"
import "./LocationFinder.style.scss"
import { useLocationFinderContext } from "Src/contexts/LocationFinderContext"

const LocationFinder = ({
  t,
  contextZipCode,
  contextIsOpen,
  contextLocationZipCode,
  changeContextState,
}) => {
  const {
    showPostalCodeEdit,
    setShowPostalCodeEdit,

    store,
    isStore,

    isLocationFinder,
    setIsLocationFinder,
    toggleLocationFinderPopup,

    validationError,
    notFoundError,

    zipCode,
    onChange,

    storeFound,
    fetchStore,
  } = useLocationFinderContext()

  const popupRef = createRef()

  useEffect(() => {
    const handleClickOutside = e => {
      if (
        popupRef.current &&
        !popupRef.current.contains(e.target) &&
        !e.target.classList.contains("Location-Finder-Button") &&
        !e.target.classList.contains("Location-Finder-Button-Arrow")
      ) {
        setIsLocationFinder(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => document.removeEventListener("mousedown", handleClickOutside)
  }, [popupRef, setIsLocationFinder])

  const getRegionalStoreName = () => {
    const { language } = getLanguage()
    const { regional_display_names } = store ?? {}

    if (regional_display_names) {
      try {
        const storeName =(regional_display_names)? regional_display_names : "";
        return storeName
      } catch (error) {
        console.error("Error parsing JSON:", error)
        return ""
      }
    } else {
      console.error("regional_display_names is not defined or null")
      return ""
    }
  }

  const renderPostalCodeEdit = () => {
    if (!showPostalCodeEdit) return null

    return (
      <div className="Change-Postal-Code">
        <div className="Change-Postal-Code-Title">
          {t("locationFinder.enterPostalCode")}
        </div>
        <div className="Store-Not-Found-Error">
          {notFoundError.length > 0 && <strong>{notFoundError}</strong>}
        </div>
        <div
          className={`Postal-Code-Input-Wrapper ${
            validationError ? "Validation-Error" : ""
          }`}
        >
          <input
            value={zipCode}
            onChange={onChange}
            // eslint-disable-next-line no-magic-numbers
            onKeyDown={({ keyCode }) => (keyCode === 13 ? fetchStore(t) : "")}
            type="text"
            className="Postal-Code-Input"
          />
          <button className="Postal-Code-Submit" onClick={() => fetchStore(t)}>
            {t("locationFinder.postalCodeSubmit")}
          </button>
        </div>
        <div className="Postal-Code-Validation-Error">{validationError}</div>
      </div>
    )
  }

  const getStoreUrl = ({ chatmeter_en_url = "", chatmeter_fr_url = "" }) => {
    const { language } = getLanguage()

    if (language === "qc_fr") {
      if (chatmeter_fr_url) return chatmeter_fr_url
    }

    if (chatmeter_en_url) return chatmeter_en_url

    return null
  }

  const formatNumber = number => {
    // eslint-disable-next-line no-useless-escape
    const phoneNo = number?.replace(/\D[^\.]/g, "") // Just in case to normalize phone number

    // eslint-disable-next-line no-magic-numbers
    return phoneNo?.length === 10
      ? `${phoneNo?.slice(0, 3)}-${phoneNo?.slice(3, 6)}-${phoneNo?.slice(6)}`
      : // eslint-disable-next-line no-magic-numbers
        `${phoneNo?.slice(0, 1)}-${phoneNo?.slice(1, 4)}-${phoneNo?.slice(
          4,
          7,
        )}-${phoneNo?.slice(7)}`
  }

  const renderLocationFinderPopup = () => {
    if (!isLocationFinder) return null

    const { city, address, state, zip_code: storeZipCode, phone } = store ?? {}

    const storeName = getRegionalStoreName()
    const storeLink = getStoreUrl(store ?? {})
    const formattedNumber = formatNumber(phone)

    if (storeFound) {
      return (
        <div className="Location-Finder-Popup" ref={popupRef}>
          <div className="Location-Finder-Popup-Container">
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button
              className="Close-Button"
              onClick={toggleLocationFinderPopup}
            />
            <div>
              <div className="Your-Store">
                <div className="Your-Store-Title">
                  {t("locationFinder.yourStore")}
                </div>
                <span>{t("locationFinder.label", { store: storeName })} </span>
                <br />
                <span>{address}</span>
                <br />
                <span>{`${city}, ${state} ${storeZipCode}`}</span>
              </div>
              <div className="Customer-Service">
                <span>{t("locationFinder.customerService")}</span>
                <a className="Customer-Service-Cta" href={`tel:${phone}`}>
                  {t("locationFinder.callUs", { phone: formattedNumber })}
                </a>
              </div>
              <div className="Chosen-Postal-Code">
                <span>{t("locationFinder.associatedPostalCode")}</span>
                <div className="Chosen-Postal-Code-Body">
                  <div className="Postal-Number">{zipCode?.toUpperCase()}</div>
                  <button
                    className="Code-Change"
                    onClick={() => setShowPostalCodeEdit(!showPostalCodeEdit)}
                  >
                    {t("locationFinder.postalCodeChange")}
                  </button>
                </div>
              </div>
            </div>
            {renderPostalCodeEdit()}
            {storeLink && (
              <Link
                isExternal
                to={storeLink}
                className="Button Postal-Code-Switch-Store"
              >
                {t("locationFinder.visitMyLocalStore")}
              </Link>
            )}
          </div>
        </div>
      )
    } else {
      return (
        <div className="Location-Finder-Popup" ref={popupRef}>
          <div className="Location-Finder-Popup-Container">
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button
              className="Close-Button"
              onClick={toggleLocationFinderPopup}
            />
            <div>
              <div className="Your-Store">
                <div className="Your-Store-Title">
                  {t("locationFinder.yourStore")}
                </div>
                <strong>{t("locationError.errorMessage")}</strong>
              </div>

              <div className="Chosen-Postal-Code">
                <span>{t("locationFinder.associatedPostalCode")}</span>
                <div className="Chosen-Postal-Code-Body">
                  <div className="Postal-Number">{zipCode?.toUpperCase()}</div>
                  <button
                    className="Code-Change"
                    onClick={() => setShowPostalCodeEdit(!showPostalCodeEdit)}
                  >
                    {t("locationFinder.postalCodeChange")}
                  </button>
                </div>
              </div>
            </div>
            {renderPostalCodeEdit()}
            {storeLink && (
              <Link
                isExternal
                to={storeLink}
                className={`Button Postal-Code-Switch-Store ${
                  !storeFound ? "disabled" : ""
                }`}
              >
                {t("locationFinder.visitMyLocalStore")}
              </Link>
            )}
          </div>
        </div>
      )
    }
  }

  if (!isStore || !isBrowser) return null

  return (
    <div className="Location-Finder-Inner">
      <button
        className="Location-Finder-Button"
        onClick={toggleLocationFinderPopup}
      >
        <LocationFinderIcon
          className="Location-Finder-Button-Icon"
          data-image
        />

        {!!getRegionalStoreName()
          ? t("locationFinder.label", { store: getRegionalStoreName() })
          : t("locationFinder.findLocation")}

        <LocationFinderArrow
          className="Location-Finder-Button-Arrow"
          data-image
        />
      </button>
      {renderLocationFinderPopup()}
    </div>
  )
}

LocationFinder.propTypes = {
  t: PropTypes.func.isRequired,
  contextZipCode: PropTypes.string.isRequired,
  contextIsOpen: PropTypes.bool.isRequired,
  contextLocationZipCode: PropTypes.string.isRequired,
  changeContextState: PropTypes.func.isRequired,
}

export default withTrans(LocationFinder)

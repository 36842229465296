import React, { useState, useRef } from "react"
import { htmlToReact } from "Utils/HtmlParser"
import PropTypes from "prop-types"
import "./FAQTab.style.scss"
import ProgressiveImage from "Components/ProgressiveImg"
import { getPreviewImageSrc } from "Utils/Helpers"

const FAQTab = ({ childrenData, title: titleProp, text: textProp }) => {
  const [isActive, setIsActive] = useState(false)
  const tabRef = useRef(null)
  const tabTextWrapperRef = useRef(null)
  const tabTextRef = useRef(null)

  const getData = ({
    data,
    children,
    name: Tag,
    attribs: { class: className } = {},
  }) => {
    if (!data) {
      return <Tag className={className}>{children.map(getData)}</Tag>
    }

    return data
  }

  const getChildrenData = (child, index) => {
    if (!child) return null

    const children = child[index]
    return <div ref={tabTextRef}>{getData(children)}</div>
  }

  const handleBlockClick = () => {
    setIsActive(!isActive)
    tabTextWrapperRef.current.style.height = isActive ? 0 : "initial"
  }

  const renderMedia = () => {
    if (!childrenData) return null

    const { 2: mediaData } = childrenData
    const imageData = mediaData.children.find(({ attribs }) =>
      attribs.class.includes("Image"),
    )
    const videoData = mediaData.children.find(({ attribs }) =>
      attribs.class.includes("Video"),
    )
    if (!imageData && !videoData) return null
    const image = imageData ? imageData.children[0] : null
    const video = videoData ? videoData.children[0] : null
    return [
      image ? (
        <ProgressiveImage
          src={getPreviewImageSrc(image.attribs.src)}
          largeSrc={image.attribs.src}
          alt={image.attribs.alt}
          key="image-0"
        />
      ) : null,
      video ? (
        <video
          src={video.attribs.src}
          alt={video.attribs.alt}
          controls
          key="video-0"
        >
          <track kind="captions" src="captions.vtt" srclang="en" />
        </video>
      ) : null,
    ]
  }

  const title = titleProp ? (
    <div>
      <p className="Title">{htmlToReact(titleProp)}</p>
    </div>
  ) : (
    getChildrenData(childrenData, 0)
  )

  const text = textProp ? (
    <div>
      <p className="Text">{htmlToReact(textProp)}</p>
    </div>
  ) : (
    getChildrenData(childrenData, 1)
  )

  if (!title || !text) return null

  return (
    <button
      className={`FAQTab ${isActive ? "isActive" : ""}`}
      onClick={handleBlockClick}
      ref={tabRef}
    >
      <div className="TitleWrapper">
        {title}
        <span className="Icon" />
      </div>
      <div className="TextWrapper" ref={tabTextWrapperRef}>
        {text}
        {renderMedia()}
      </div>
    </button>
  )
}

FAQTab.propTypes = {
  childrenData: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  ),
  title: PropTypes.string,
  text: PropTypes.string,
}

FAQTab.defaultProps = {
  title: null,
  text: null,
  childrenData: null,
}

export default FAQTab

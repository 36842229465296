import React, { cloneElement, useRef } from "react"
import PropTypes from "prop-types"

import "./HeightTransition.style.scss"

const HeightTransition = ({ children, isOpen }) => {
  const ref = useRef(null)

  const clientHeight = ref.current ? ref.current.clientHeight : 0
  const style = { height: isOpen ? clientHeight : 0 }

  return (
    <div className="HeightTransition" style={style}>
      {cloneElement(children, { ref: ref })}
    </div>
  )
}

HeightTransition.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
}

export default HeightTransition

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useSharedContext } from "Src/contexts/SharedContext"
import BlogOlderPosts from "./BlogOlderPosts.component"
import { getLanguage } from "Utils/Language"

export const INITIAL_SHOWED_POST_COUNT = 3
export const LOAD_MORE_INCREMENT = 3

const BlogOlderPostsContainer = ({
  posts = [],
  prevBlocksShowedPostsCount = 0,
  blockTitle = "",
  loadMoreButton = "",
  backgroundColor = "",
}) => {
  const { activePageCategory } = useSharedContext()
  const [showedPostCount, setShowedPostCount] = useState(
    INITIAL_SHOWED_POST_COUNT,
  )
  const [isAllPostsVisible, setIsAllPostsVisible] = useState(false)

  useEffect(() => {
    if (activePageCategory) {
      setShowedPostCount(INITIAL_SHOWED_POST_COUNT)
      setIsAllPostsVisible(false)
    }
  }, [activePageCategory])

  const getPostMap = () => {
    const activeCategory = activePageCategory?.name
    const language = getLanguage().url.replace("/", "")

    return posts
      .filter(post => {
        if (
          post.lang !== language ||
          (activeCategory &&
            !post.categories?.some(
              ({ category_name }) =>
                category_name.toLowerCase() === activeCategory.toLowerCase(),
            ))
        ) {
          return false
        }
        return true
      })
      .slice(0, showedPostCount)
  }

  const handleLoadMore = totalCount => {
    const nextShowedPostCount = showedPostCount + LOAD_MORE_INCREMENT
    const nextIsAllPostsVisible = totalCount <= nextShowedPostCount
    setShowedPostCount(nextIsAllPostsVisible ? totalCount : nextShowedPostCount)
    setIsAllPostsVisible(nextIsAllPostsVisible)
  }

  const totalCount =
    posts.length > prevBlocksShowedPostsCount
      ? posts.length - prevBlocksShowedPostsCount
      : 0

  return totalCount ? (
    <BlogOlderPosts
      postMap={getPostMap()}
      blockTitle={blockTitle}
      isAllPostsVisible={
        isAllPostsVisible || totalCount <= INITIAL_SHOWED_POST_COUNT
      }
      handleLoadMore={() => handleLoadMore(totalCount)}
      loadMoreButton={loadMoreButton}
      backgroundColor={backgroundColor}
      activePageCategoryName={activePageCategory?.name}
    />
  ) : null
}

BlogOlderPostsContainer.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  prevBlocksShowedPostsCount: PropTypes.number,
  blockTitle: PropTypes.string,
  loadMoreButton: PropTypes.string,
  backgroundColor: PropTypes.string,
}

BlogOlderPostsContainer.defaultProps = {
  posts: [],
  prevBlocksShowedPostsCount: 0,
  blockTitle: "",
  loadMoreButton: "",
  backgroundColor: "",
}

export default BlogOlderPostsContainer
